import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;
    max-width: 70%;

    background: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.15, theme.colors.Background)
        : lighten(0.15, theme.colors.Background)};
    border-radius: 1.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 3.5rem;
      font-family: Poppins;
      align-self: flex-start;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
    }

    span {
      font-family: Poppins;
      font-size: 2.5rem;
      margin-top: 2rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
    }

    div {
      width: 100%;
      display: flex;
      margin-top: 2rem;

      button {
        flex: 1;
        border: 0;
        font-size: 2.5rem;
        font-family: Poppins;
        background: ${({ theme }) => theme && theme.colors.Background};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.9, theme.colors.Background)
            : lighten(0.9, theme.colors.Background)};
        padding: 1.5rem;
        border-radius: 0.5rem;

        &:last-child {
          font-weight: bold;
          background: ${({ theme }) =>
            theme && lighten(0.2, theme.colors.SecondaryColor)};
          color: ${({ theme }) => theme && theme.colors.DefaultColor};
          margin-left: 1rem;
        }
      }
    }
  }
`;
