import { useContext } from 'react';
import EmptyBanner from '../../../assets/empty-banner.png';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { MenuListContext } from '../../../contexts/MenuListContext';
import { SubgroupContext } from '../../../contexts/SubgroupContext';
import { GroupItemList } from '../GroupItemList/index';
import { ProductItemList } from '../ProductItemList';
import { Container } from './styles';

export function MenuContent(): JSX.Element {
  const { selectedCompany } = useContext(CompanyContext);
  const { selectedGroup } = useContext(MenuListContext);
  const { selectedSubgroup } = useContext(SubgroupContext);

  const banner = {
    path:
      Object.keys(selectedSubgroup).length === 0
        ? selectedGroup.banner
        : selectedSubgroup.subGrupo.banner,
    title:
      Object.keys(selectedSubgroup).length === 0
        ? selectedGroup.nome
        : selectedSubgroup.subGrupo.nome,
  };

  return (
    <Container
      banner={banner.path !== '' ? banner.path : EmptyBanner}
      readOnlyMenu={selectedCompany.id === 8}
      titleLong={banner.title?.length > 15}
    >
      {(() => {
        if (selectedGroup.possuiSubGrupo) {
          if (Object.keys(selectedSubgroup).length === 0) {
            return <GroupItemList key={selectedGroup.idGrupo} />;
          }
        }
        return <ProductItemList key={selectedGroup.idGrupo} />;
      })()}
    </Container>
  );
}
