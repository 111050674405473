import axios, { AxiosRequestConfig } from 'axios';

const url = 'https://api.connx.com.br';
const api = axios.create({
  baseURL: url,
  withCredentials: true,
});

api.interceptors.request.use(
  (config): AxiosRequestConfig => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error('Axios interceptor: ', error);
  },
);

export default api;
