import React, { useContext, useState } from 'react';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { CompanyContext } from '../../../contexts/CompanyContext';

import { Popover } from '../../../components/utils/Popover';
import { Content, Item } from './styles';

interface CompanyItemProps {
  company: {
    id: number;
    nome: string;
    descricao: string;
    ativo: boolean;
    logotipo: string;
    aberto: boolean;
  };
}

export function CompanyItem({ company }: CompanyItemProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { selectCompany } = useContext(CompanyContext);

  async function handleSelectCompany(id: number) {
    await selectCompany(id);
  }

  function handleTogglePopover(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    e.stopPropagation();
    setIsPopoverOpen(!isPopoverOpen);
  }

  function handleClickDescription(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) {
    e.stopPropagation();
  }

  return (
    <Item
      key={company.id}
      isActive={company.aberto}
      type="button"
      disabled={false}
      onClick={() => handleSelectCompany(company.id)}
    >
      <section>
        <img src={company.logotipo} alt={company.nome} />
      </section>

      <strong>{company.nome}</strong>
      <span>{company.aberto ? 'ABERTO' : 'FECHADO'}</span>
      <Popover
        isOpen={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        padding={10}
        align="center"
        content={() => (
          <Content onClick={(e) => handleClickDescription(e)}>
            {company.descricao}
          </Content>
        )}
        positions={['bottom']}
        containerStyle={{ maxWidth: '80%' }}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={(e) => handleTogglePopover(e)}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <IoInformationCircleSharp />
        </div>
      </Popover>
    </Item>
  );
}
