/* eslint-disable prettier/prettier */
import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.footer`
  min-height: 12vh;
  max-height: 30vh;
  position: relative;
  margin: 0 -2rem -1rem -2rem;
  display: flex;
  flex-direction: column;
  border-top: 2px solid #ececec;
  width: 95%;
  padding: 0;
  margin: 1rem;
  overflow: hidden;
  margin-top:auto;

  @media screen and (min-width: 900px) and (max-width: 1366px) {
    max-height: max-content;
  }

  div:first-child{
    button{
      background-color: transparent;//${({ theme }) => theme && theme.colors.Background};
      margin-top: 2rem;
      @media screen and (min-width: 900px) and (max-width: 1366px) {
        margin-top: 0;
    }
    }
  }

  /* div {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    font: 2rem Poppins;
    align-items: center;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};

    span {
      font: 2.5rem Poppins;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
    }
  } */

  div {
    button {
      height: 5rem;
      width: 100%;
      border: 0;
      font-family: Poppins;
      font-size: 2.2rem;
      border-radius: 0.5rem;
      padding: 0;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};

      @media screen and (min-width: 900px) and (max-width: 1366px) {
        height: 3rem;
        font-size: 1.5rem;
    }
    }
  }

  div:last-child {
    display: flex;
    gap: 2rem;

    button {
      flex: 1;
      border: 0;
      font-family: Poppins;
      font-size: 2.2rem;
      border-radius: 0.5rem;
      @media screen and (min-width: 900px) and (max-width: 1366px) {
        font-size: 1.5rem;
    }

      &:first-child {
        background: ${({ theme }) => theme && theme.colors.Background};
        color: ${({ theme }) => theme && theme.colors.DefaultColor};
        border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
      }

      &:last-child {
        font-weight: 600;
        background: ${({ theme }) => theme && theme.colors.DefaultColor};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.DefaultColor).isLight()
            ? darken(0.9, '#ffffff')
            : lighten(0.9, '#ffffff')};
        border: 0;
      }
    }
  }
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1rem;
`;

interface ValueContainerProps{
  hidden?:boolean
  isTotal?:boolean
}

export const ValueContainer = styled.div<ValueContainerProps>`
  display: ${(props)=> props.hidden? 'none':'flex'};
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 0 2rem;
  font: 2rem Poppins;
  align-items: center;
  color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isLight()
      ? darken(0.8, theme.colors.Background)
      : lighten(0.8, theme.colors.Background)};

  strong {
    font-weight: 400;
  }

  span {
    font: 2.5rem Poppins;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};
        font-weight:  ${({ isTotal }) => isTotal? 600:400};
  }
`;

export const CupomArea = styled.div`
  gap: 4rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 900px) and (max-width: 1366px) {
    gap: 2rem;
  }

  span{
    display: flex;
    gap: 1rem;
    span{
      font-weight:700;
    }
  }

  #containerButtonDiscont{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;
    padding: 0;
    margin-bottom: 1rem;

    #discont {
      width: 4rem;
      height: 4rem;
      display:flex;
      align-items: center;
      justify-content: center;
      border-radius: 10%;
      margin-bottom: 1rem;
      border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
    }
  }
`;
