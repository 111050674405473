import { useContext } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Container } from './styles';

interface ModalMensageProps {
  isOpen: boolean;
  message: string;
}

export function ModalMensage({
  isOpen,
  message,
}: ModalMensageProps): JSX.Element {
  const { setOpenIsMsgBox } = useContext(CompanyContext);
  function goback() {
    setOpenIsMsgBox(false);
    // eslint-disable-next-line no-restricted-globals
  }

  return (
    <Container isOpen={isOpen}>
      <div>
        <FaExclamationCircle />
        <h1>Aviso!!</h1>
        <h1>{message}</h1>
        <div>
          <button type="button" onClick={goback}>
            OK
          </button>
        </div>
      </div>
    </Container>
  );
}
