import { useContext, useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
// eslint-disable-next-line import/no-extraneous-dependencies
import Checked from '../../../../../assets/checked-icon.svg';
import { ModalContext } from '../../../../../contexts/ModalContext';
import { ProductContext } from '../../../../../contexts/ProductContext';
import { MultipleQuestion } from '../MultipleQuestion';
import { UniqueQuestion } from '../UniqueQuestion';
import { Container } from './styles';

export function ModalContent(): JSX.Element {
  const { questions, selectedItem, observation, setObservation } =
    useContext(ModalContext);
  const { selectedProduct } = useContext(ProductContext);

  const [isKeyboard, setIsKeyboard] = useState(false);
  const [layoutName, setLayoutName] = useState('default');
  const keyboard = useRef();

  const handleShift = () => {
    setLayoutName(layoutName === 'default' ? 'shift' : 'default');
  };

  function onKeyPress(button: string) {
    if (button === '{shift}' || button === '{lock}') handleShift();
  }

  function setFocus(e: any) {
    if (e !== null || e !== undefined) {
      setIsKeyboard(true);
    }
  }

  useEffect(() => {
    if (isKeyboard) {
      const divs = document.getElementById('scroll_Observacao');
      divs?.scrollTo(0, divs?.scrollHeight + 200);
    }
  }, [isKeyboard]);

  return (
    <Container id="scroll_Observacao">
      {questions.map((question) =>
        !(question.pergunta.minimo === 1 && question.pergunta.maximo === 1) ? (
          <div key={question.pergunta.id}>
            <header>
              <span>{question.pergunta.nome}</span>
              <div>
                <span>{`Escolha até ${question.pergunta.maximo} opções`}</span>
                {question.pergunta.minimo >= 1 &&
                  (question.count < question.pergunta.minimo ? (
                    <div>OBRIGATÓRIO</div>
                  ) : (
                    <img src={Checked} alt="Checked" />
                  ))}
              </div>
            </header>
            <ul>
              {question.pergunta.perguntaItens.map((item) => (
                <MultipleQuestion
                  key={item.id}
                  questionItem={item}
                  questionId={question.pergunta.id}
                  itemId={item.id}
                  totalCount={question.count}
                  maxQuantity={question.pergunta.maximo}
                  hidePrice={false}
                />
              ))}
            </ul>
          </div>
        ) : (
          <div key={question.pergunta.id}>
            <header>
              <span>{question.pergunta.nome}</span>
              <div>
                <span>{`${question.count} de ${question.pergunta.maximo}`}</span>
                {question.pergunta.minimo === 1 &&
                  (question.count === 0 ? (
                    <div>OBRIGATÓRIO</div>
                  ) : (
                    <img src={Checked} alt="Checked" />
                  ))}
              </div>
            </header>
            <ul>
              {question.pergunta.perguntaItens.map((item) => (
                <UniqueQuestion
                  key={item.id}
                  questionId={question.pergunta.id}
                  questionItem={item}
                  hidePrice={false}
                />
              ))}
            </ul>
          </div>
        ),
      )}
      {(selectedProduct.exibirObservacao || selectedItem.isObservation) && (
        <div>
          <header>
            <span>Observação</span>
            <div>
              <span>{`Usado ${observation.length} de 150 caracteres.`}</span>
            </div>
          </header>
          <ul>
            {selectedItem?.observation &&
              setObservation(selectedItem.observation)}
            <textarea
              onFocus={(e) => setFocus(e)}
              placeholder="Digite sua observação do produto"
              defaultValue={observation}
              maxLength={150}
            />
          </ul>
          {isKeyboard && (
            <Keyboard
              // eslint-disable-next-line no-return-assign
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layoutName}
              onKeyPress={(e: any) => onKeyPress(e)}
              onChange={(e: any) => {
                if (e.length <= 150) setObservation(e);
              }}
            />
          )}
        </div>
      )}
    </Container>
  );
}

export function ModalContentObs(): JSX.Element {
  const { selectedItem, observation, setObservation } =
    useContext(ModalContext);

  const [isKeyboard, setIsKeyboard] = useState(false);
  const [layoutName, setLayoutName] = useState('default');
  const keyboard = useRef();

  const handleShift = () => {
    setLayoutName(layoutName === 'default' ? 'shift' : 'default');
  };

  function onKeyPress(button: string) {
    if (button === '{shift}' || button === '{lock}') handleShift();
  }

  function setFocus(e: any) {
    if (e !== null || e !== undefined) {
      setIsKeyboard(true);
    }
  }

  useEffect(() => {
    if (selectedItem?.observation) {
      setObservation(selectedItem?.observation);
    }
  });

  return (
    <Container>
      <div>
        <header>
          <span>Observação</span>
          <div>
            <span>{`Usado ${observation.length} de 150 caracteres`}</span>
          </div>
        </header>
        <ul>
          <textarea
            onFocus={(e) => setFocus(e)}
            placeholder="Digite sua observação do produto"
            defaultValue={observation}
            maxLength={150}
          />
        </ul>
        {isKeyboard && (
          <Keyboard
            // eslint-disable-next-line no-return-assign
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layoutName}
            onKeyPress={(e: any) => onKeyPress(e)}
            onChange={(e: any) => {
              if (observation.length <= 150) setObservation(e);
            }}
          />
        )}
      </div>
    </Container>
  );
}
