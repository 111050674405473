import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme && theme.colors.Background};
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s linear;

  section {
    margin-top: 2rem;
    height: 14rem;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      margin: 0 auto;
      height: 100%;
      width: auto;
      border-radius: 8%;
      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? 'trasparent'
          : lighten(1, theme.colors.Background)};
    }
  }

  #PIX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -3rem;
    gap: 2rem;
    h1 {
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
      font: 8rem Poppins;
      line-height: 4rem;
      text-align: center;

      margin-top: -3rem;
      font-weight: 600;
    }

    img {
      width: 140%;
      margin-top: 1rem;
      border-radius: 1rem;
    }
  }

  div {
    h1 {
      margin-top: -5rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
      font: 3.25rem Poppins;
      line-height: 4rem;
      text-align: center;

      font-weight: 600;
    }
  }

  & > div {
    flex: 1;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s linear;
    max-width: 100%;

    div:last-child {
      display: flex;
      align-items: baseline;
      span {
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.2, theme.colors.SecondaryColor)
            : lighten(0.15, theme.colors.SecondaryColor)};
        font-family: Poppins;
        font-size: 2rem;
        margin-right: 1.5rem;
      }
    }
  }

  #loading {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.2, theme.colors.SecondaryColor)
          : lighten(0.15, theme.colors.SecondaryColor)};
      font-family: Poppins;
      font-size: 2rem;
      margin-right: 1.5rem;
    }
  }
`;

const dotAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme && theme.colors.DefaultColor};
  border-radius: 50%;
  margin: 0 5px;
  animation: ${dotAnimation} 1.5s infinite;
`;

export const ThreeDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 10%;
`;
