/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
import { useContext, useEffect } from 'react';
import Lottie from 'react-lottie';
import arrowDown from '../../../../../assets/animations/down-arrow.json';
import { ModalContext } from '../../../../../contexts/ModalContext';
import {
  Button,
  ButtonContainer,
  ModalContainer,
  ModalContent,
} from './styles';
/* const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingBeer,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}; */

export function ModalCreditNFC(): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowDown,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const {
    closeCreditModal,
    confirmCreditModal,
    documentInvalidMessage,
    setDocumentInvalidMessage,
  } = useContext(ModalContext);
  let tag = '';

  useEffect(() => {
    if (documentInvalidMessage !== '') {
      setDocumentInvalidMessage(documentInvalidMessage);
    }
  }, [documentInvalidMessage, setDocumentInvalidMessage]);

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') tag += event.key;
    if (event.key === 'Enter') {
      confirmCreditModal(tag);
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <ModalContainer onClick={closeCreditModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Lottie options={defaultOptions} width="20rem" isClickToPauseDisabled />
        <h1>Aproxime o cartão do leitor NFC</h1>
        <ButtonContainer isGoBack>
          <Button isGoBack onClick={closeCreditModal}>
            Cancelar
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
}
