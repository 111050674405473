import styled, { css } from 'styled-components';

interface ContentProps {
  isOdd: boolean;
}

export const Container = styled.main`
  background: ${({ theme }) => theme && theme.colors.Background};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Animation = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  background: ${({ theme }) => theme && theme.colors.Background};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    max-width: 60%;
    padding: 2rem;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      flex: 1;
      padding: 1rem;

      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 200;
        font-size: 1.8rem;
        text-align: center;
        color: #fff;
      }

      form {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        border-radius: 0.5rem;
        border: 2px solid #ececec;

        input {
          flex: 1;
          border: 0;
          width: 100%;
          font-size: 2rem;
          padding: 1rem;
          color: #242424;
          background: #fff;
          transition: border 0.2s;

          &::placeholder {
            color: #d6d6d6;
          }

          &:focus {
            border: 2px solid #ff9e1b;
          }

          &:focus::placeholder {
            color: #757575;
          }
        }

        button {
          font-size: 3rem;
          margin-right: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background: #fff;
          color: #242424;
        }
      }

      & > div {
        flex: 1;
        margin: 0 auto;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        section {
          width: 100%;
          display: flex;

          & + section {
            margin-top: 1.5rem;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 5rem;
            min-width: 5rem;
            margin: 0 1rem;
            font-size: 3rem;
            border: 0;
            border-radius: 0.25rem;
            transition: transform 0.2s ease, box-shadow 0.2s ease;

            background: #f5f5f5;
            border: solid 1px #ff9e1b;

            box-shadow: 1px 0px 0px #ff9e1b, 0px 1px 0px #ff9e1b,
              1px 1px 0px #ff9e1b, 1px 1px 0px #ff9e1b, 2px 1px 0px #ff9e1b,
              1px 2px 0px #ff9e1b, 2px 2px 0px #ff9e1b, 2px 2px 0px #ff9e1b,
              3px 2px 0px #ff9e1b, 2px 3px 0px #ff9e1b, 3px 3px 0px #ff9e1b,
              3px 3px 0px #ff9e1b, 4px 3px 0px #ff9e1b, 3px 4px 0px #ff9e1b,
              4px 4px 0px #ff9e1b, 4px 4px 0px #ff9e1b, 5px 4px 0px #ff9e1b,
              4px 5px 0px #ff9e1b;

            &:active {
              transition: transform 0.2s ease, box-shadow 0.2s ease;

              transform: translateY(6px) translateX(6px);

              box-shadow: 0px 0px 0px #1976d2, 0px 0px 0px #1976d2,
                0px 0px 0px #1976d2, 0px 0px 0px #1976d2;
            }
          }

          &:last-child {
            button:last-child {
              flex: 1;
              max-width: none;
            }
          }
        }
      }

      footer {
        display: flex;
        margin-top: 3rem;

        button {
          flex: 1;
          padding: 1rem;
          border-radius: 0.5rem;
          border: 0;

          color: #fff;
          background: #2e2e2e;

          font-family: Heinberg;
          font-weight: 400;
          font-size: 2rem;

          &:last-child {
            margin-left: 1rem;
            background: #ff9e1b;
          }
        }
      }
    }
  }
`;

export const ContentDefault = styled.div<ContentProps>`
  height: 100%;

  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Heinberg;

  button {
    margin-top: 5rem;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    align-self: flex-start;
    font-size: 8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      flex: 1;

      color: ${({ theme }) => theme && theme.colors.DefaultColor};
    }
  }

  h1 {
    font-size: 5rem;
    font-weight: 700;
    color: ${({ theme }) => theme && theme.colors.SecondaryColor};

    margin-top: 2rem;
  }

  div {
    margin-top: 10rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;

    button {
      grid-column: span 2;
      display: flex;
      justify-content: center;
      padding: 2rem;
      border: 0;
      border-radius: 0.5rem;
      background: ${({ theme }) => theme && theme.colors.Background};
      color: ${({ theme }) => theme && theme.colors.SecondaryColor};

      font-size: 5rem;
      font-family: Heinberg;

      ${({ isOdd }) =>
        isOdd &&
        css`
          &:last-child {
            grid-column: 2 / span 2;
          }
        `}
    }
  }
`;
