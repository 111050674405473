import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background-color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.2, theme.colors.Background)
        : lighten(0.15, theme.colors.Background)};
    max-width: 60%;
    padding: 2rem;
    border-radius: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      flex: 1;
      padding: 1rem;

      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 800;
        font-size: 2.25rem;
        text-align: center;
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(1, theme.colors.Background)
            : lighten(1, theme.colors.Background)};
      }
      h1:last-child {
        font-weight: 800;
        font-size: 2.25rem;
        text-align: center;
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.3, theme.colors.DefaultColor)
            : lighten(0.3, theme.colors.DefaultColor)};
      }

      form {
        display: flex;
        align-items: center;
        margin-top: 3rem;
        border-radius: 0.5rem;
        border: 2px solid #ececec;
        background: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0, theme.colors.Background)
            : lighten(1, theme.colors.Background)};

        input {
          font-family: Poppins;
          flex: 1;
          border: 0;
          font-size: 2rem;
          padding: 1rem;
          color: #242424;
          background: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0, theme.colors.Background)
              : lighten(1, theme.colors.Background)};
          transition: border 0.2s;

          &::placeholder {
            color: ${({ theme }) =>
              theme && tinycolor(theme.colors.Background).isLight()
                ? darken(0.1, theme.colors.Background)
                : theme.colors.Background};
          }

          &:focus {
            border: 2px solid
              ${({ theme }) => theme && theme.colors.DefaultColor};
          }

          &:focus::placeholder {
            color: ${({ theme }) =>
              theme && tinycolor(theme.colors.Background).isLight()
                ? darken(0, theme.colors.Background)
                : theme.colors.Background};
          }
        }
        button {
          font-size: 3rem;
          margin-right: 0.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          background: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0, theme.colors.Background)
              : lighten(1, theme.colors.Background)};
          color: #242424;

          svg {
            flex: 1;
            color: ${({ theme }) => theme && theme.colors.SecondaryColor};
          }
        }
      }

      & > div {
        flex: 1;
        margin: 0 auto;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        section {
          width: 100%;
          display: flex;

          & + section {
            margin-top: 1.5rem;
          }

          button {
            font-family: Poppins;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 5rem;
            min-width: 5rem;
            margin: 0 1rem;
            font-size: 5rem;
            border: 0;
            border-radius: 0.25rem;
            transition: transform 0.2s ease, box-shadow 0.2s ease;

            background: #f5f5f5;
            border: solid 1px
              ${({ theme }) => theme && theme.colors.DefaultColor};

            box-shadow: 1px 0px 0px
                ${({ theme }) => theme && theme.colors.DefaultColor},
              0px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              1px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              2px 1px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              1px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              2px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              3px 2px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              2px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              3px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              4px 3px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              3px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              4px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              5px 4px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
              4px 5px 0px ${({ theme }) => theme && theme.colors.DefaultColor};

            &:active {
              transition: transform 0.2s ease, box-shadow 0.2s ease;

              transform: translateY(6px) translateX(6px);

              box-shadow: 0px 0px 0px
                  ${({ theme }) => theme && theme.colors.DefaultColor},
                0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
                0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor},
                0px 0px 0px ${({ theme }) => theme && theme.colors.DefaultColor};
            }
          }

          &:last-child {
            button:last-child {
              flex: 1;
              max-width: none;
            }
          }
        }
      }

      footer {
        display: flex;
        margin-top: 3rem;

        button {
          font-family: Poppins;
          flex: 1;
          padding: 1rem;
          border-radius: 0.5rem;
          border: 0;

          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0.9, theme.colors.Background)
              : lighten(0.9, theme.colors.Background)};
          background: ${({ theme }) => theme && theme.colors.Background};

          font-weight: 600;
          font-size: 2rem;

          &:last-child {
            font-weight: 800;
            margin-left: 1rem;
            color: ${({ theme }) => theme && theme.colors.DefaultColor};
            background: ${({ theme }) =>
              theme && tinycolor(theme.colors.DefaultColor).isLight()
                ? darken(0.2, theme.colors.SecondaryColor)
                : lighten(0.2, theme.colors.SecondaryColor)};
          }
        }
      }
    }
  }
`;
