import { useContext } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CompanyContext } from '../../../../contexts/CompanyContext';
import { Container } from './styles';

interface ModalMensageProps {
  isOpen: boolean;
  senha: string;
  empresa: number;
  idPedido: string;
  isNotPaper: boolean;
}
window.bound = window.bound || {};
export function ModalMensageError({
  isOpen,
  senha,
  empresa,
  idPedido,
  isNotPaper,
}: ModalMensageProps): JSX.Element {
  const { needPayment, setIsOrderFinalized, serviceElectron, setIsLoading } =
    useContext(CompanyContext);

  const history = useHistory();

  function goBack() {
    setTimeout(() => {
      history.push('/');
      setIsOrderFinalized(true);
    }, 15500);
  }

  const tentarImprimir = async () => {
    setIsLoading(true);
    try {
      let imprimiu;
      if (Object.keys(window.bound).length > 0) {
        if (needPayment) {
          imprimiu = await window.bound.imprimirComprovantePagamentoPedido(
            empresa,
            idPedido,
            false,
          );
        } else {
          imprimiu = await window.bound.imprimirResumoPedido(empresa, idPedido);
        }
      } else if (serviceElectron?.isElectronApp) {
        if (needPayment) {
          imprimiu = await serviceElectron?.ipcRenderer?.invoke(
            'imprimir-comprovante-pagamento-pedido',
            {
              Empresa: empresa,
              IdPedido: idPedido,
            },
          );
        } else {
          imprimiu = await serviceElectron?.ipcRenderer?.invoke(
            'imprimir-resumo-pedido',
            { Empresa: empresa, IdPedido: idPedido },
          );
        }
      }
      if (imprimiu === 'OK') {
        goBack();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {serviceElectron.isElectronApp && (
        <Container isOpen={isOpen}>
          <div>
            <FaExclamationCircle />
            <strong>{`Anote sua Senha: ${senha}`}</strong>
            {isNotPaper ? (
              <>
                <h1>Impressora esta sem Papel. Solicite a troca, por favor</h1>
                <h1>
                  Por favor, anote sua senha para aguardar a chamada do
                  pedido!!!
                </h1>
              </>
            ) : (
              <>
                <h1>Tivemos problema na comunicação com a impressora.</h1>
                <h1>
                  Por favor, anote sua senha para aguardar chamada do pedido!!!
                </h1>
              </>
            )}

            <div>
              <button
                type="button"
                onClick={async () => tentarImprimir()}
                disabled={isNotPaper}
                hidden={isNotPaper}
              >
                Tentar novamente
              </button>
              <button
                type="button"
                onClick={() => {
                  if (isNotPaper) history.push('/');
                  else if (serviceElectron?.isElectronApp)
                    serviceElectron?.ipcRenderer?.invoke('ReiniciarTotem');
                }}
              >
                {isNotPaper ? 'Trocado Papel' : 'OK, senha anotada'}
              </button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
