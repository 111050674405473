/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useState } from 'react';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { CompanyContext } from '../../../../../contexts/CompanyContext';
import { ModalContext } from '../../../../../contexts/ModalContext';
import { Container } from './styles';

export function ModalCredit(): JSX.Element {
  const { isOrderFinalized } = useContext(CompanyContext);
  const {
    closeCreditModal,
    addIdNumber,
    documentNumber,
    confirmCreditModal,
    documentInvalidMessage,
    setDocumentInvalidMessage,
  } = useContext(ModalContext);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isOrderFinalized) setShow(false);
  }, [isOrderFinalized]);

  useEffect(() => {
    if (documentInvalidMessage !== '') setDocumentInvalidMessage('');
  }, [documentInvalidMessage, setDocumentInvalidMessage]);

  const applyCPFFormat = (value) => {
    // Remove todos os caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    // Aplica a máscara "999.999.999-99"
    const maskedValue = numericValue.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4',
    );

    return maskedValue;
  };

  return (
    <Container onClick={closeCreditModal}>
      <div onClick={(e) => e.stopPropagation()}>
        <div>
          <h1>Informe seu CPF para vincularmos a sua conta no app MyTapp:</h1>
          <form>
            <input
              type={show ? 'text' : 'password'}
              value={applyCPFFormat(documentNumber)}
              readOnly
            />
            <button type="button" onClick={() => setShow(!show)}>
              {!show ? <VscEyeClosed /> : <VscEye />}
            </button>
          </form>
          <div>
            <section>
              <button type="button" onClick={() => addIdNumber('7')}>
                7
              </button>
              <button type="button" onClick={() => addIdNumber('8')}>
                8
              </button>
              <button type="button" onClick={() => addIdNumber('9')}>
                9
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumber('4')}>
                4
              </button>
              <button type="button" onClick={() => addIdNumber('5')}>
                5
              </button>
              <button type="button" onClick={() => addIdNumber('6')}>
                6
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumber('1')}>
                1
              </button>
              <button type="button" onClick={() => addIdNumber('2')}>
                2
              </button>
              <button type="button" onClick={() => addIdNumber('3')}>
                3
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumber('0')}>
                0
              </button>
              <button type="button" onClick={() => addIdNumber('delete')}>
                <RiDeleteBack2Fill />
              </button>
            </section>
          </div>
          <footer>
            <button type="button" onClick={closeCreditModal}>
              Cancelar
            </button>
            <button type="button" onClick={() => confirmCreditModal()}>
              Adicionar no carrinho
            </button>
          </footer>
        </div>
      </div>
    </Container>
  );
}
