import { useContext } from 'react';
import EmptyImage from '../../../../../assets/empty-product.png';
import { ModalContext } from '../../../../../contexts/ModalContext';
import { ProductContext } from '../../../../../contexts/ProductContext';
import { Header, HeaderNotQuestions } from './styles';

interface HeaderModalProps {
  hasQuestions: boolean;
}

export function HeaderModal({ hasQuestions }: HeaderModalProps): JSX.Element {
  const { selectedProduct } = useContext(ProductContext);
  const { selectedItem } = useContext(ModalContext);

  let image = selectedProduct.imagem;
  let name = selectedProduct.nome;
  let description = selectedProduct.descricao;
  if (Object.keys(selectedItem).length !== 0) {
    image = selectedItem.image;
    name = selectedItem.name;
    description = selectedItem.description;
  }

  if (!hasQuestions) {
    return (
      <HeaderNotQuestions>
        <strong>{name}</strong>
        <section>
          <img src={image !== '' ? image : EmptyImage} alt="Produto" />
        </section>
        <span>{description}</span>
      </HeaderNotQuestions>
    );
  }

  return (
    <Header caracteres={description?.length || 0}>
      <div>
        <section>
          <img src={image !== '' ? image : EmptyImage} alt="Produto" />
          <strong>{name}</strong>
        </section>
        <span id="description">{description}</span>
      </div>
    </Header>
  );
}
