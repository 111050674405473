import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.main`
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  overflow: auto;
  width: 100%;
  height: 100%;
  max-height: 25rem;
  margin-top: 1rem;
  @media screen and (min-width: 900px) and (max-width: 1366px) {
    max-height: 18rem;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  & > ul {
    width: 100%;
    list-style: none;
    padding: 0 2rem;
    gap: 2rem;
    @media screen and (min-width: 900px) and (max-width: 1366px) {
      gap: 1rem;
    }

    & > li {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
      position: relative;
      gap: 2rem;

      & + li {
        padding-top: 1rem;
      }

      &:not(:last-child)::before {
        content: '';
        position: absolute;
        left: 1%;
        bottom: 0;
        height: 1px;
        width: 98%; /* or 100px */
        border-bottom: 1px solid;
        border-color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.3, theme.colors.Background)
            : lighten(0.6, theme.colors.Background)};
      }

      div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0;
        width: 100%;

        strong {
          font-family: Poppins;
          font-size: 1.875rem;
          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0.8, theme.colors.Background)
              : lighten(0.8, theme.colors.Background)};
        }

        ul {
          margin-top: 0;
          list-style: none;

          li {
            display: flex;
            align-items: center;

            & + li {
              margin-top: 0.75rem;
            }

            span:first-child {
              font-family: Poppins;
              display: flex;
              justify-content: center;
              text-align: center;
              /* background: ${({ theme }) =>
                theme && tinycolor(theme.colors.Background).isLight()
                  ? darken(0.3, theme.colors.Background)
                  : lighten(0.8, theme.colors.Background)}; */
              color: ${({ theme }) => theme && theme.colors.DefaultColor};
              border-radius: 0.25rem;
              font-size: 1.25rem;
            }

            span {
              font-family: Poppins;
              font-size: 1.2rem;
              margin-left: 0.5rem;
              color: ${({ theme }) =>
                theme && tinycolor(theme.colors.Background).isLight()
                  ? darken(0.8, theme.colors.Background)
                  : lighten(0.8, theme.colors.Background)};
            }
          }
        }

        & > span {
          font-family: Poppins;
          font-size: 2rem;
          font-weight: bold;
          margin-top: 1rem;
          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0.8, theme.colors.Background)
              : lighten(0.8, theme.colors.Background)};
        }
      }

      aside {
        width: 8.5rem;
        display: flex;
        align-items: center;
        //border: 1px solid #ececec;
        position: relative;

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: 0 auto;
          border-radius: 8px;
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          position: absolute;
          right: 0;
          left: 5.2rem;
          bottom: -1rem;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          font-family: Poppins;

          background: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? lighten(0.13, theme.colors.SecondaryColor)
              : lighten(0.1, theme.colors.SecondaryColor)};
          color: ${({ theme }) => theme && theme.colors.DefaultColor};
        }
      }
    }
  }
`;
