/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Form } from './Form';
import { Container } from './styles';

export function ModalLogin() {
  return (
    <Container>
      <div>
        <Form />
      </div>
    </Container>
  );
}
