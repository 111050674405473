/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prettier/prettier */

import Touch from '../../assets/LogoTouchBranco.png';
import TouchBanner from '../../assets/bannerPadrao.png';
import { ModalLogin } from '../../components/ModalLogin';
import { useMain } from '../../hook/useMain';
// import { Users } from '../../utils/AlertaRT';
import { Banner, Carrocel } from './styles';


declare global {
  interface Window {
    bound: any;
  }
}

window.bound = window.bound || {};

export function Main(): JSX.Element {
  const {
    imgs,
    logo,
    logoPe,
    logoPd,
    logoPor,
    handleStart,
    totemName,
    isModalLoginVisible,
  } = useMain();

  const sliderSettings = {
    speed: 1000,
    infinite: true,
    slideToShow: 1,
    slideToScroll: 1,
    waitForAnimate: true,
    dots: true,
    dotsClass: 'slick-dots',
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1000*35,
    adaptiveHeight: true,
    arrows:false,
    touchMove:true,
    swipeToSlide: true,
    transformEnabled: false,
  }

  const imgFunction = () => {
    if (imgs.length > 0) {
      return (
        <Carrocel
          {...sliderSettings}
        >
          {imgs?.map(data => (
            <img
              key={data}
              className='banners'
              alt={data}
              src={data}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src ='https://static.wikia.nocookie.net/lotr/images/6/66/Talion_At_Minas_Morgul.jpg/revision/latest?cb=20181114134856'
              }}
            />
          ))}
        </Carrocel>
      )
    }
    return <img src={TouchBanner} alt="Padrao" id="banner_padrao" />;
  };

  return (
    <>
      {isModalLoginVisible && <ModalLogin />}
      <Banner
        Load={imgs.length !== 0 && imgs[0] !== ''}
        logope={logoPe}
        logoPor={logoPor}
        logopd={logoPd}
      >
        <button onClick={handleStart}>
          <div className="imgSlider">
            {imgFunction()}
          </div>
          <img src={logo} alt="Logo" />
          <div>
            <strong>TOQUE PARA COMEÇAR</strong>
            <h1>{totemName}</h1>
            <img src={Touch} alt="connx" id='comercial_connx'  />
          </div>
        </button>
      </Banner>
    </>
  );
}

export default Main;
 /* <AppCard>
                    {Users.includes(totemID||'') &&
            (
              <>
                <h1>Acesse nosso cardápio Digital</h1>
                <main>
                  <section>
                    <img src={qrCodeCardapioRT} alt="qrcode" />
                  </section>
                </main>
              </>
            )
            (
              <>
                <h1>Acesse nosso cardápio Digital</h1>
                <main>
                  <section>
                    <img src={qrCodeCardapioRT} alt="qrcode" />
                  </section>
                </main>
              </>
            )
          }
        </AppCard> */
