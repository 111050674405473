import { useContext } from 'react';
import { useTheme } from 'styled-components';
import { CompanyContext } from '../../../contexts/CompanyContext';
import { MenuListContext } from '../../../contexts/MenuListContext';
import { Menu } from './styles';

import EmptyImage from '../../../assets/empty-product.png';
import { SubgroupContext } from '../../../contexts/SubgroupContext';

export function MenuList(): JSX.Element {
  const { selectedCompany } = useContext(CompanyContext);
  const { selectGroup, selectedGroup, groups } = useContext(MenuListContext);
  const { selectSubgroup } = useContext(SubgroupContext);
  const theme = useTheme();
  const handleSelectGroup = (id: number) => {
    selectSubgroup(0);
    selectGroup(id);
  };

  return (
    <Menu>
      <header>
        <section>
          <img src={selectedCompany.logotipo} alt={selectedCompany.nome} />
        </section>
      </header>
      <ul>
        {groups.map((group) => (
          <li key={group.idGrupo}>
            <button
              type="button"
              onClick={() => handleSelectGroup(group.idGrupo)}
              style={
                selectedGroup.idGrupo === group.idGrupo
                  ? {
                      transform: 'scale(1.3)',
                    }
                  : {}
              }
            >
              <img
                src={group.imagem !== '' ? group.imagem : EmptyImage}
                alt={group.nome}
              />
              <span
                style={
                  selectedGroup.idGrupo === group.idGrupo
                    ? {
                        color: theme.colors.DefaultColor,
                        opacity: 1,
                        fontWeight: 600,
                      }
                    : {}
                }
              >
                {group.nome}
              </span>
            </button>
          </li>
        ))}
      </ul>
      <span id="statusCompany" hidden={selectedCompany.aberto}>
        FECHADO
      </span>
    </Menu>
  );
}
