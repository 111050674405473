/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckoutContext, LastOrder } from '../contexts/CheckoutContext';
import { CompanyContext } from '../contexts/CompanyContext';
import { NumberContext } from '../contexts/NumberContext';
import { PlataformaSistemicaEnum } from '../enum/PlataformaSistemicaEnum.enum';
import api from '../services/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useChoosePaymentMethod() {
  const history = useHistory();
  const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { mobileNumber, cpf, email, dataDesconto } = useContext(NumberContext);
  const [isOpenPIX, setIsOpenPIX] = useState(false);
  const [isAviso, setIsAviso] = useState(false);
  const [msgAviso, setMsgAviso] = useState('');

  const {
    paymentMethods,
    selectedCompany,
    setOpenIsMsgBox,
    setIsMsgBox,
    setIsLoading,
    serviceElectron,
    isLoading,
  } = useContext(CompanyContext);
  const {
    orderItems,
    setNewLastOrder,
    lastOrder,
    orderPrice,
    setConnection,
    typeDelivery,
  } = useContext(CheckoutContext);

  useEffect(() => {
    if (paymentMethods.length === 0) {
      history.push('/');
      return;
    }

    paymentMethods.forEach((pay) => {
      if (pay.descricao === 'PIX' && orderPrice < 1) {
        setMsgAviso(
          'O pagamento via PIX só é permitido para pedidos acima de R$ 1,00.',
        );
        setIsAviso(true);
      } else {
        setMsgAviso('');
        setIsAviso(false);
      }
    });
  }, []);

  async function confirmOrder(paymentMethodId: number, qtdParcelas = 0) {
    setIsLoading(true);
    const formPagto = paymentMethods.find(
      (x) => x.formaPagamento === paymentMethodId,
    );

    /*   if (formPagto?.qtdVezesParcelamento && formPagto?.pagamentoAVista === false)
      if (
        formPagto?.qtdVezesParcelamento > 1 &&
        !(qtdParcelas >= 1 && formPagto?.qtdVezesParcelamento <= qtdParcelas)
      ) {
        return;
      } */

    const order = {
      idEmpresa: selectedCompany.id,
      chaveSeguranca: btoa(
        `${selectedCompany.id}@IDX@SISTEMAS_${selectedCompany.id}`,
      ),
      formaPagamento: paymentMethodId,
      desconto:
        dataDesconto.tipoValor === 0
          ? orderPrice * (dataDesconto.valorDesconto / 100)
          : dataDesconto.valorDesconto,
      cpf,
      email,
      cupomDescontoId: dataDesconto.id,
      observacao: '',
      telefone: mobileNumber,
      typeDeliverySelected: typeDelivery,
      pedidoItem: orderItems.map((item) => {
        return {
          item: item.id,
          TagRfid: item.tagRfid,
          Document: item.document,
          idProduto: item.productId,
          qtde: item.quantity,
          vlUnitario: item.unitPrice,
          desconto: 0,
          observacao: item.observation,
          grupoId: item.grupoId,
          subGrupoId: item.subGrupoId,
          pedidoSubItem: item.subItems.map((subItem) => {
            return {
              item: subItem.id,
              idPergunta: subItem.questionId,
              idPerguntaItemSelecionado: subItem.selectedSubItemId,
              qtde: subItem.quantity,
              observacao: '',
            };
          }),
        };
      }),
    };

    try {
      const responseOrder = await api.post('api/v1/Pedido', order);

      const lastOrder2: LastOrder = {
        ...responseOrder.data,
      };

      serviceElectron?.ipcRenderer?.invoke('Log', JSON.stringify(lastOrder2));
      serviceElectron?.ipcRenderer?.invoke('Log', 'Processando pagamento');

      if (serviceElectron?.isElectronApp && lastOrder2) {
        serviceElectron?.ipcRenderer?.invoke(
          'Log',
          'Processando pagamento isElectronApp',
        );
        if (
          formPagto?.plataformaSistemica === PlataformaSistemicaEnum.DLLDESTAXA
        )
          setConnection(new WebSocket('ws://127.0.0.1:5027/destaxaTef'));
        const responsePaygo = await serviceElectron?.ipcRenderer?.invoke(
          'processaPagamento',
          {
            Empresa: selectedCompany.id,
            PedidoId: lastOrder2.idPedido,
            MetodoPagamento: paymentMethodId,
            PwinfoPaymntType:
              formPagto?.plataformaSistemica !==
              PlataformaSistemicaEnum.DLLDESTAXA
                ? '1'
                : 'A Vista',
          },
        );
        if (!responsePaygo.Sucesso) {
          setErrorMessage(responsePaygo.Msg);
          setIsModalPaymentOpen(true);
          return;
        }

        if (responsePaygo.Dados.StatusIntencaoVenda === 20) {
          setErrorMessage(responsePaygo.Dados.ResultMsg);
          setIsModalPaymentOpen(true);
          return;
        }
      }

      if (
        formPagto?.plataformaSistemica !== PlataformaSistemicaEnum.DLLDESTAXA
      ) {
        setNewLastOrder(lastOrder2);
        history.push('/success');
      } else {
        setNewLastOrder(lastOrder2);
        history.push('/tefdestaxa');
      }
    } catch (err: any) {
      setOpenIsMsgBox(true);
      serviceElectron?.ipcRenderer?.invoke('LogError', `Pagamento ${err}`);
      if (!navigator.onLine) setIsAlertDialogOpen(true);
      if (
        err?.response?.data?.mensagem?.includes(
          'O Item selecionado da Pergunta',
        ) ||
        err?.response?.data?.mensagem?.includes('Favor selecionar no mínimo') ||
        err?.response?.data?.mensagem?.includes('Favor selecionar no máximo')
      ) {
        setIsMsgBox(err?.response?.data?.mensagem);
        setOpenIsMsgBox(true);
      }
      if (err?.response?.data?.mensagem) {
        setIsMsgBox(err.response.data.mensagem);
        setOpenIsMsgBox(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function retryOrder() {
    setIsModalPaymentOpen(false);
  }

  function cancelOrder() {
    setIsModalPaymentOpen(false);
    history.push('/');
  }

  function goBack() {
    if (Object.keys(lastOrder).length > 0) setNewLastOrder({} as LastOrder);
    history.goBack();
  }

  return {
    goBack,
    isModalPaymentOpen,
    errorMessage,
    retryOrder,
    cancelOrder,
    isAlertDialogOpen,
    setIsAlertDialogOpen,
    isOpenPIX,
    setIsOpenPIX,
    lastOrder,
    paymentMethods,
    confirmOrder,
    orderPrice,
    msgAviso,
    isAviso,
    selectedCompany,
    isLoading,
  };
}
