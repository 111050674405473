import { Container } from './styles';

interface ModalConfirmProps {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export function ModalConfirm({
  title,
  description,
  onCancel,
  onConfirm,
}: ModalConfirmProps) {
  return (
    <Container>
      <div>
        <h1>{title}</h1>
        <span>{description}</span>
        <div>
          <button type="button" onClick={() => onCancel()}>
            Cancelar
          </button>
          <button type="button" onClick={() => onConfirm()}>
            Confirmar
          </button>
        </div>
      </div>
    </Container>
  );
}
