import { useContext, useEffect, useState } from 'react';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Animation, Content } from './styles';

import Loading from '../../assets/animations/loadings/loading_padrao.json';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { NumberContext } from '../../contexts/NumberContext';
import api from '../../services/api';

export function OrderConfirmed(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { mobileNumber, code, setCode, addIdNumberCode } =
    useContext(NumberContext);
  const { createOrder } = useContext(CheckoutContext);
  const { needPayment } = useContext(CompanyContext);

  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
  };

  useEffect(() => {
    setCode('');
  }, [setCode]);

  async function confirmCode(): Promise<boolean> {
    try {
      const response = await api.post(
        `api/v1/PhoneAuthorization/AuthorizePhone/${mobileNumber}/${code}`,
        null,
        {
          withCredentials: true,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function confirmOrder() {
    setIsLoading(true);

    if (!(await confirmCode())) {
      setIsLoading(false);
      return;
    }

    if (needPayment) {
      history.push('/payment');
    } else {
      try {
        await createOrder(0);
        history.push('/success');
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Content>
      {isLoading && (
        <Animation>
          <Lottie
            options={defaultOptions}
            isClickToPauseDisabled
            height="70%"
          />
        </Animation>
      )}
      <div>
        <div>
          <h1>Informe o Codigo de Confirmação</h1>
          <form>
            <input type="text" value={code} disabled />
          </form>
          <div>
            <section>
              <button type="button" onClick={() => addIdNumberCode('7')}>
                7
              </button>
              <button type="button" onClick={() => addIdNumberCode('8')}>
                8
              </button>
              <button type="button" onClick={() => addIdNumberCode('9')}>
                9
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumberCode('4')}>
                4
              </button>
              <button type="button" onClick={() => addIdNumberCode('5')}>
                5
              </button>
              <button type="button" onClick={() => addIdNumberCode('6')}>
                6
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumberCode('1')}>
                1
              </button>
              <button type="button" onClick={() => addIdNumberCode('2')}>
                2
              </button>
              <button type="button" onClick={() => addIdNumberCode('3')}>
                3
              </button>
            </section>
            <section>
              <button type="button" onClick={() => addIdNumberCode('0')}>
                0
              </button>
              <button type="button" onClick={() => addIdNumberCode('delete')}>
                <RiDeleteBack2Fill />
              </button>
            </section>
          </div>
          <footer>
            <button type="button" onClick={() => history.goBack()}>
              Voltar
            </button>
            <button
              type="button"
              disabled={isLoading}
              onClick={() => confirmOrder()}
            >
              Confirmar
            </button>
          </footer>
        </div>
      </div>
    </Content>
  );
}
