import { useContext } from 'react';
import EmptyImage from '../../../../../assets/empty-image.png';
import { CompanyContext } from '../../../../../contexts/CompanyContext';
import { Container } from './styles';

export function Header(): JSX.Element {
  const { selectedCompany } = useContext(CompanyContext);

  return (
    <Container>
      <h1>Resumo do pedido</h1>
      <div>
        <section>
          <img
            src={
              selectedCompany.logotipo !== ''
                ? selectedCompany.logotipo
                : EmptyImage
            }
            alt="Logotipo da empresa"
          />
        </section>
        <h1>{selectedCompany.nome}</h1>
      </div>
    </Container>
  );
}
