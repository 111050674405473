import { createContext, ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CompanyContext } from './CompanyContext';

interface PaymentContextProps {
  isLoading: boolean;
  isModalPaymentOpen: boolean;
  cancelOrder: () => void;
  retryOrder: () => void;
}

interface PaymentProviderProps {
  children: ReactNode;
}

export interface LastOrder {
  idPedido: string;
  numeroIdentificador: string;
  dataPedido: string;
  desconto: number;
  observacao: string;
  total: number;
}

declare global {
  interface Window {
    bound: any;
  }
}

export const PaymentContext = createContext({} as PaymentContextProps);

export function PaymentProvider({ children }: PaymentProviderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false);

  const history = useHistory();

  const { setIsOrderFinalized } = useContext(CompanyContext);

  function cancelOrder() {
    setIsOrderFinalized(true);
    setIsModalPaymentOpen(false);
    history.push('/');
  }

  function retryOrder() {
    setIsModalPaymentOpen(false);
  }

  return (
    <PaymentContext.Provider
      value={{
        isLoading,
        isModalPaymentOpen,
        cancelOrder,
        retryOrder,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}
