import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

const fadeInBottom = keyframes`
  ${'0%'} {
    opacity: 0;
    margin-top: 10rem;
  }
  ${'100%'} {
    opacity: 1;
    margin-top: 0;
  }
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 28rem;
  background-color: ${({ theme }) => theme && theme.colors.Background};
  padding-top: 1.25rem;
  height: 100%;
  animation: ${fadeInBottom} 1s;
  list-style-type: none;
  justify-items: center;
  align-items: flex-start;

  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      margin-top: 5rem;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.li`
  align-self: center;
  display: inline-flex;
  justify-content: center;
  padding: 0 1.875rem;
  grid-column: span 2;
  width: 100%;
  height: 15rem;
  max-width: 25rem;

  &:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }

  button {
    height: 100%;
    border: 0;
    background: ${({ theme }) => theme && theme.colors.Background};
    border-radius: 5px;
    padding: 1.25rem 1.875rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border: 1px solid
      ${(props) =>
        tinycolor(props.theme.colors.Background).isLight()
          ? '#dbdbdb'
          : '#332F2E'};

    img {
      height: 7.5rem;
      max-width: 18rem;
      object-fit: cover;
    }

    span {
      font-family: Poppins;
      font-size: 1.5rem;
      margin-top: 1.5rem;
      color: ${(props) =>
        tinycolor(props.theme.colors.Background).isLight()
          ? '#262626'
          : lighten(0.8, '#262626')};
    }
  }
`;
