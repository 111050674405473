import {
  Button,
  ButtonContainer,
  ModalContainer,
  ModalContent,
} from './styles';

interface RechargeModalProps {
  goBack: () => void;
  onOptionSelect: (option: number) => void;
}

export default function ModalSelectCredit({
  goBack,
  onOptionSelect,
}: RechargeModalProps): JSX.Element {
  return (
    <ModalContainer onClick={goBack}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h1>Escolha a forma de recarga:</h1>
        <ButtonContainer>
          <Button onClick={() => onOptionSelect(1)}>Por Aproximação</Button>
          <Button isLast onClick={() => onOptionSelect(0)}>
            Por CPF
          </Button>
        </ButtonContainer>
        <ButtonContainer isGoBack>
          <Button isGoBack onClick={goBack}>
            Voltar
          </Button>
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
}
