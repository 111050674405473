import { FaExclamationCircle } from 'react-icons/fa';
import { Container } from './styles';

interface AlertDialogProps {
  onClose: () => void;
}

export function AlertDialog({ onClose }: AlertDialogProps): JSX.Element {
  return (
    <Container>
      <div>
        <FaExclamationCircle />
        <h1>Ops, houve um problema! Entre em contato com o administrador.</h1>
        <button type="button" onClick={onClose}>
          Voltar
        </button>
      </div>
    </Container>
  );
}
