import { useContext } from 'react';

import { NumberFormat } from '../../../../../components/NumberFormat';
import {
  ModalContext,
  QuestionSubItem,
} from '../../../../../contexts/ModalContext';
import { Item } from './styles';

interface QuestionProps {
  questionItem: QuestionSubItem;
  questionId: number;
  hidePrice: boolean;
}

export function UniqueQuestion({
  questionItem,
  questionId,
  hidePrice,
}: QuestionProps) {
  const { questions, changeItem } = useContext(ModalContext);

  return (
    <Item>
      <label htmlFor={questionItem.opcao}>
        <input
          type="radio"
          name={questionId.toString()}
          id={questionItem.opcao}
          defaultChecked={
            questions.find((question) => question.pergunta.id === questionId)
              ?.selectedItem === questionItem.id
          }
          onClick={() => changeItem(questionItem, questionId)}
        />
        <div>
          {questionItem.opcao}
          {!hidePrice && (
            <NumberFormat prefix="(+ " suffix=")">
              {questionItem.preco}
            </NumberFormat>
          )}
        </div>
      </label>
    </Item>
  );
}
