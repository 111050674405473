/* eslint-disable no-shadow */
export enum PlataformaSistemicaEnum {
  CONTROLPAY = 1,
  DLLPAYGO = 2,
  GATE2ALL = 3,
  TOUCHEAT = 4,
  PAGSEGURO_PIX = 5,
  DLLSITEF = 6,
  DLLDESTAXA = 7,
}
