/* eslint-disable jsx-a11y/control-has-associated-label */
import { useContext } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { NumberFormat } from '../../../../../components/NumberFormat';
import { CompanyContext } from '../../../../../contexts/CompanyContext';
import { ModalContext } from '../../../../../contexts/ModalContext';
import { ProductContext } from '../../../../../contexts/ProductContext';
import { Footer } from './styles';

export function FooterModal(): JSX.Element {
  const { selectedProduct } = useContext(ProductContext);
  const {
    finalCount,
    finalPrice,
    disableCheckout,
    addToCart,
    closeModal,
    handleDecrementFinalCount,
    handleIncrementFinalCount,
    selectedItem,
  } = useContext(ModalContext);
  const { readOnlyMenu } = useContext(CompanyContext);

  let buttonName: any = 'Adicionar';
  let unitPrice = !selectedProduct.valorPromocional
    ? selectedProduct.preco
    : selectedProduct.valorPromocional;

  let oldUnitPrice = !selectedProduct.preco
    ? selectedProduct.valorPromocional
    : selectedProduct.preco;
  let { ativo } = selectedProduct;

  if (Object.keys(selectedProduct).length === 0) {
    buttonName = 'Atualizar';
    unitPrice = selectedItem.unitPrice;
    oldUnitPrice = selectedItem.unitPrice;
    ativo = selectedItem.ativo;
  } else if (selectedProduct.geraValorEmCredito) {
    buttonName = 'Continuar';
  }

  return (
    <Footer>
      {!ativo || readOnlyMenu ? (
        <>
          <div>
            <h1>Valor total</h1>
            <NumberFormat prefix="R$">
              {(finalPrice === 0 ? unitPrice : unitPrice + finalPrice) *
                finalCount}
            </NumberFormat>
          </div>
          <div>
            <button type="button" onClick={() => closeModal()}>
              Voltar
            </button>
          </div>
        </>
      ) : (
        <>
          <div>
            <section>
              <button
                type="button"
                disabled={finalCount === 1}
                onClick={() => handleDecrementFinalCount()}
              >
                <FaMinus />
              </button>
              <input type="button" readOnly value={finalCount} />
              <button
                type="button"
                disabled={finalCount === 10}
                onClick={() => handleIncrementFinalCount()}
              >
                <FaPlus />
              </button>
            </section>
            <div className="prices">
              <NumberFormat prefix="R$" className="price">
                {(finalPrice === 0 ? unitPrice : unitPrice + finalPrice) *
                  finalCount}
              </NumberFormat>
              <NumberFormat
                prefix="R$"
                className="oldPrice"
                hidden={!selectedProduct.valorPromocional}
              >
                {(finalPrice === 0 ? oldUnitPrice : oldUnitPrice + finalPrice) *
                  finalCount}
              </NumberFormat>
            </div>
          </div>
          <div>
            <button type="button" onClick={() => closeModal()}>
              Cancelar
            </button>
            <button
              type="button"
              onClick={addToCart}
              disabled={disableCheckout}
            >
              {buttonName}
            </button>
          </div>
        </>
      )}
    </Footer>
  );
}
