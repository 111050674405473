import { useContext } from 'react';

import { Item, List } from './styles';

import EmptyImage from '../../../assets/empty-product.png';
import { SubgroupContext } from '../../../contexts/SubgroupContext';

export function GroupItemList(): JSX.Element {
  const { subGroups, selectSubgroup } = useContext(SubgroupContext);

  return (
    <List>
      {subGroups.map((subGroup) => (
        <Item key={subGroup.idSubGrupo}>
          <button
            type="button"
            onClick={() => selectSubgroup(subGroup.idSubGrupo)}
          >
            <img
              src={
                subGroup.subGrupo.imagem !== ''
                  ? subGroup.subGrupo.imagem
                  : EmptyImage
              }
              alt={subGroup.subGrupo.nome}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = EmptyImage;
              }}
            />
            <span>{subGroup.subGrupo.nome}</span>
          </button>
        </Item>
      ))}
    </List>
  );
}
