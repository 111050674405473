import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CompanyContext } from './CompanyContext';

interface ModalReviewContextData {
  isModalReviewVisible: boolean;
  isModalDiscontVisible: boolean;
  changeIsModalReviewVisible: (isVisible: boolean) => void;
  changeIsmodalDiscontVisible: (isVisible: boolean) => void;
}

interface ModalReviewProviderProps {
  children: ReactNode;
}

export const ModalReviewContext = createContext({} as ModalReviewContextData);

export function ModalReviewProvider({ children }: ModalReviewProviderProps) {
  const { isOrderFinalized } = useContext(CompanyContext);
  const [isModalReviewVisible, setIsModalReviewVisible] = useState(false);
  const [isModalDiscontVisible, setIsModalDiscontVisible] = useState(false);

  useEffect(() => {
    if (!isOrderFinalized) return;
    setIsModalReviewVisible(false);
    setIsModalDiscontVisible(false);
  }, [isOrderFinalized]);

  function changeIsModalReviewVisible(isVisible: boolean) {
    setIsModalReviewVisible(isVisible);
  }

  function changeIsmodalDiscontVisible(isVisible: boolean) {
    setIsModalReviewVisible(!isVisible);
    setIsModalDiscontVisible(isVisible);
  }

  return (
    <ModalReviewContext.Provider
      value={{
        isModalReviewVisible,
        isModalDiscontVisible,
        changeIsmodalDiscontVisible,
        changeIsModalReviewVisible,
      }}
    >
      {children}
    </ModalReviewContext.Provider>
  );
}
