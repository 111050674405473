import { FormHandles, useField } from '@unform/core';
import { useEffect, useRef } from 'react';

interface Props {
  name: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export function Input({ name, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return <input id={fieldName} ref={inputRef} {...rest} />;
}
