import { darken } from 'polished';
import styled, { keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

const fadeInBottom = keyframes`
  ${'0%'} {
    opacity: 0;
    margin-top: 25rem;
  }
  ${'100%'} {
    opacity: 1;
    margin-top: 20rem;
  }
`;

export const Menu = styled.aside`
  grid-area: ml;
  max-height: 100%;
  min-width: 6.25rem;
  overflow: scroll;
  background: ${({ theme }) => theme && theme.colors.Background};
  margin-bottom: 3rem;

  &::-webkit-scrollbar {
    display: none;
  }

  header {
    width: 28%;
    height: 16rem;
    position: fixed;
    background: ${({ theme }) => theme && theme.colors.Background};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25) {
      height: 10rem;
    }

    section {
      height: 14rem;
      width: 14rem;
      position: relative;
      overflow: hidden;
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
        height: 8rem;
        width: 8rem;
      }

      img {
        display: block;
        margin: 0 auto;
        height: 100%;
        width: auto;
        border-radius: 8%;
      }
    }
  }

  ul {
    animation: ${fadeInBottom} 1s;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme && theme.colors.Background};
      list-style: none;
      margin-top: 20rem; /* hack para aparecer todos os itens sem o header cobrir*/
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
        margin-top: 11rem;
      }

      & + li {
        margin: 4rem 0;
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
          margin: 2rem;
        }
      }

      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 65%;
        padding: 1.25rem;
        border: 0;
        border-radius: 10px;
        background-color: ${({ theme }) => theme && theme.colors.Background};
        font-size: 2rem;
        transition: all 0.2s ease-in-out;
        :hover {
          background: transparent;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
          padding: 0;
        }

        img {
          width: 6.5rem;
          display: block;
          margin: 0 auto;
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
            width: 2rem;
          }
        }

        span {
          font-family: 'Poppins';
          //font-weight: 600;
          font-size: 1.5rem;
          margin-top: 1.25rem;
          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0.3, theme.colors.SecondaryColor)
              : theme.colors.SecondaryColor};
          opacity: 0.5;

          @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
            font-size: 1rem;
            margin-top: 0.25rem;
          }
        }
      }
    }
  }

  #statusCompany {
    background: #e6674e;
    padding: 0.5rem;
    color: #fff;
    width: 24%;
    font-size: 0.75rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 1.5%;
    top: 15.5%;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    @media (min-width: 1124px) {
      font-size: 1.5rem;
    }

    @media (orientation: landscape) {
      width: 25.5%;
      left: 0%;
      top: 8.2%;
    }
  }
`;
