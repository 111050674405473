import { darken, lighten } from 'polished';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

interface GridProps {
  readOnlyMenu: boolean;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 1.1fr 2.9fr;
  grid-template-rows: ${({ readOnlyMenu }) =>
    readOnlyMenu ? '92vh 8vh' : '100%'};
  grid-template-areas: ${({ readOnlyMenu }) =>
    readOnlyMenu ? "'ml mc' 'back back'" : "'ml mc'"};
  background-color: ${({ theme }) => theme && theme.colors.Background};
`;

export const GoBackContainer = styled.div`
  grid-area: back;

  background: ${({ theme }) => theme && theme.colors.Background};

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.5rem;

  button {
    flex: 1;
    max-width: 80%;
    height: 100%;

    border-radius: 1rem;
    background: ${({ theme }) => theme && theme.colors.SecondaryColor};
    font-size: 4rem;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0, theme.colors.Background)
        : lighten(0.9, theme.colors.Background)};

    :hover {
      background: transparent;
    }
  }
`;

export const ToastContainerTotem = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width: 60%;
  }
  .Toastify__toast {
    //border-radius: 10%;
  }
  .Toastify__toast-body {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .Toastify__toast-icon {
    margin-right: 1.5rem;
    width: 4rem;
    height: 4rem;
  }
  .Toastify__progress-bar {
    height: 5px;
  }
`;
