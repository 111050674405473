import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

interface UniqueQuestionProps {
  theme: {
    modal: {
      questionNameColor: string;
      questionPriceColor: string;
      radioBackgroundColor: string;
      radioSelectBackgroundColor: string;
    };
  };
}

export const Item = styled.li<UniqueQuestionProps>`
  display: flex;
  height: 7rem;
  align-items: center;
  position: relative;

  @media screen and (min-width: 900px) and (max-width: 1420px) {
    height: 4rem;
  }

  & + li:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    width: 100%; /* or 100px */
    border-bottom: 1px solid #dadad9;
  }

  label {
    height: 100%;

    font-size: 1.75rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    cursor: pointer;
    @media screen and (min-width: 900px) and (max-width: 1420px) {
      font-size: 1rem;
    }

    input {
      margin-right: 1.5rem;
      display: inline-block;
      position: relative;
      border: 0;

      &::after {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        position: absolute;
        top: -25%;
        left: -25%;
        background-color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.3, theme.colors.Background)
            : lighten(0.8, theme.colors.Background)};

        content: '';
        display: flex;
        visibility: visible;
      }

      &:checked::after {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        top: -25%;
        left: -25%;
        position: absolute;
        background-color: ${({ theme }) => theme && theme.colors.DefaultColor};

        content: '';
        display: flex;
        visibility: visible;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};

      span {
        font-family: 'Poppins';
        margin-top: 0.125rem;
        font-size: 1.25rem;
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.8, theme.colors.Background)
            : lighten(0.8, theme.colors.Background)};
      }
    }
  }
`;
