/* eslint-disable react/jsx-no-bind */
import { useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../../../contexts/ModalContext';
import { ProductContext } from '../../../../contexts/ProductContext';
import { FooterModal } from './FooterModal';
import { HeaderModal } from './HeaderModal';
import { ModalContent, ModalContentObs } from './ModalContent';
import { ModalCredit } from './ModalCredit';
import { ModalCreditNFC } from './ModalCreditNFC';
import ModalSelectCredit from './ModalSelectCredit';
import { Container } from './styles';

export function Modal(): JSX.Element {
  const { questions, isModalCreditVisible, selectedItem, closeCreditModal } =
    useContext(ModalContext);
  const { selectedProduct } = useContext(ProductContext);
  const [isModalCreditDocumentVisible, setIsModalCreditDocumentVisible] =
    useState(false);
  const [isModalCreditNFCVisible, setIsModalCreditNFCVisible] = useState(false);
  const [isModalCreditSelectVisible, setIsModalCreditSelectVisible] =
    useState(false);

  function handleOptionSelect(option: number) {
    setIsModalCreditSelectVisible(false);
    if (option === 0) setIsModalCreditDocumentVisible(true);
    else setIsModalCreditNFCVisible(true);
  }

  useEffect(() => {
    setIsModalCreditSelectVisible(isModalCreditVisible);
    if (!isModalCreditVisible) {
      setIsModalCreditDocumentVisible(false);
      setIsModalCreditNFCVisible(false);
    }
  }, [isModalCreditVisible]);

  return (
    <Container
      hasQuestions={questions.length !== 0}
      hasObservacao={
        selectedItem?.isObservation || selectedProduct?.exibirObservacao
      }
    >
      {isModalCreditNFCVisible && <ModalCreditNFC />}
      {isModalCreditDocumentVisible && <ModalCredit />}
      {isModalCreditSelectVisible && (
        <ModalSelectCredit
          goBack={closeCreditModal}
          onOptionSelect={handleOptionSelect}
        />
      )}
      <div>
        <HeaderModal hasQuestions={questions.length !== 0} />
        {questions.length !== 0 ? (
          <ModalContent />
        ) : (
          (selectedItem?.isObservation ||
            selectedProduct?.exibirObservacao) && <ModalContentObs />
        )}
        <FooterModal />
      </div>
    </Container>
  );
}
