import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    border-radius: 0.5rem;
    flex: 1;
    justify-content: center;
  }
`;
