/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable prettier/prettier */

import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Touch from '../../assets/LogoTouchBranco.png';
import TouchLogo from '../../assets/TouchD.png';
import { Screen } from './styles';
import Connx from '../../assets/connxLogo.png'
import { CompanyContext} from '../../contexts/CompanyContext';
import { loadingLogoElectron } from '../../utils/imagensFront';
import { ElectronService } from '../../lib/electron.service';
import api from '../../services/api';

declare global {
  interface Window {
    bound: any;
  }
}

window.bound = window.bound || {};

export function Maintenance(): JSX.Element {
  const {
    totemName,
    serviceElectron,
    selectedCompany,
    companies,
    loadCompanies,
    setTotemMaintenance
  } = useContext(CompanyContext);

  const [logo, setLogo] = useState('')
  const [logoPe, setLogoPe] = useState(false);
  const [logoPd, setLogoPd] = useState(true);
  const [logoPor, setLogoPor] = useState(0);

  async function logins(){
    if (serviceElectron.isElectronApp || localStorage.getItem('totemLogin')) {
      try {
        let response;
        if (localStorage.getItem('totemLogin') && !serviceElectron.isElectronApp) {
          const { userName, password } = JSON.parse(
              localStorage.getItem('totemLogin') || '{}',
          );
          const bodyRequest = {
              userName,
              password: Buffer.from(password, 'base64').toString('utf-8'),
          };
          const respAPI = await api.post('api/v1/users/login', bodyRequest, {
              withCredentials: true,
          });
          response = respAPI.data;
        } else response = await serviceElectron?.ipcRenderer?.invoke('login');

        if (!response) {
        alert('Erro na autenticação do terminal! Verifique usuário/senha ou entre em contato com o administrador.');
        }
        const {
            expiration,
            token,
            user: { userName, painelSenha, totem, },
            totem: { totemMobile,thisMaintenance},
            } = response;
        if (!painelSenha && !totemMobile && totem) {
          window.localStorage.setItem('dataExpire', expiration)
          window.localStorage.setItem('user', userName);
          window.localStorage.setItem('token', token);
          setTotemMaintenance(thisMaintenance)
        } else {
          window.localStorage.removeItem('user');
          window.localStorage.removeItem('token');
          alert('Erro na autenticação do terminal! Verifica se usuário é tipo Totem.');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    loadCompanies()
    const interval = setInterval(()=> logins(), 1000*60*5)
    return ()=>{
      clearInterval(interval)
    }
  }, []);

  useEffect(() => {
    const fetchCompanyLogo = async () => {
      if (serviceElectron.isElectronApp) {
        try {
          await loadingLogoElectron(
            { setLogo, setLogoPe, setLogoPd, setLogoPor },
            serviceElectron
          );
        } catch (error) {
          toast.error('Erro ao carregar o logo.');
        }
      }
      else {
        setLogoPd(true);
        setLogo(TouchLogo);
      }
    };

    fetchCompanyLogo();
  }, []);

  return (
    <Screen>
      <div />
      <div className='description-area'>
        <img src={Connx} alt="Alerta" className='alertImage' />
        <h1 className='title-main'>
          EQUIPAMENTO EM
          <span>MANUTENÇÃO</span>
        </h1>
        <h1 className='title'>REALIZAR PEDIDO DIRETAMENTE NO RESTAURANTE</h1>
      </div>
      <div className='companies-images'>
        {companies.map((company, index) => (
          <img className="img-companies" key={index} src={`${company.logotipo}`} alt={company.nome} />
        ))}
      </div>
      <div className='content'>
        <img src={logo} alt="Logo" className='imgLogo' />
        <div>
          <h1>{totemName}</h1>
          <img src={Touch} alt="connx" id='comercial_connx' />
        </div>
      </div>
    </Screen>
  );
}

 /* <AppCard>
                    {Users.includes(totemID||'') &&
            (
              <>
                <h1>Acesse nosso cardápio Digital</h1>
                <main>
                  <section>
                    <img src={qrCodeCardapioRT} alt="qrcode" />
                  </section>
                </main>
              </>
            )
            (
              <>
                <h1>Acesse nosso cardápio Digital</h1>
                <main>
                  <section>
                    <img src={qrCodeCardapioRT} alt="qrcode" />
                  </section>
                </main>
              </>
            )
          }
        </AppCard> */
