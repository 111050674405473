/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import { useContext, useRef, useState } from 'react';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputMask from 'react-input-mask';
import { KeyboardReactInterface } from 'react-simple-keyboard';
import { useTheme } from 'styled-components';
import { CompanyContext } from '../../../../contexts/CompanyContext';
import { ModalReviewContext } from '../../../../contexts/ModalReviewContext';
import { NumberContext } from '../../../../contexts/NumberContext';
import api from '../../../../services/api';
import {
  Container,
  ContainerModal,
  ErrorMessage,
  KeyboardContainer,
  NumberKeyboard,
  StyledKeyboard,
} from './styles';

/* interface ModalDiscontProps {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
} */

export interface CupomDesconto {
  sucesso: boolean;
  mensagem: string;
  dados: IDataDesconto;
}
export interface IDataDesconto{
  valorDesconto: number;
  tipoValor: number;
  id: string;
}

export function ModalDiscont(): JSX.Element {
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [isKeyboard, setIsKeyboard] = useState(true);
  const [layoutName, setLayoutName] = useState('default');
  const keyboard = useRef<KeyboardReactInterface | null>();

  const { messageErrorCpf, addIdCpfCode, setCpf, setCupom, cpf, dataDesconto, cupom, confirmCPFModal, setDataDesconto, setMessageErrorCpf} =
    useContext(NumberContext);
  const { setIsLoading, isLoading, selectedCompany } =
    useContext(CompanyContext);
  const { changeIsmodalDiscontVisible, changeIsModalReviewVisible } =
    useContext(ModalReviewContext);

  const handleShift = () => {
    setLayoutName(layoutName === 'default' ? 'shift' : 'default');
  };

  const clearKeyboard = () => {
    setCupom(cupom.slice(0, -1))
    keyboard.current?.setInput(cupom.slice(0, -1))
  }

  function onKeyPress(button: string) {
    if (button === '{shift}' || button === '{lock}') handleShift();
    if (button === '{bksp}') clearKeyboard();
  }

  async function sendCode() {
    setIsLoading(true);
    setMessageErrorCpf('')

    try {
      if (!confirmCPFModal()) return setIsLoading(false);
      if(cupom === ''){
        setErrorMessage("Cupom não pode ser vázio!");
        setIsLoading(false);
        return;
      }
      const response = await api.post<CupomDesconto>(
        `api/v1/CupomDesconto/ValidarCupomDesconto/${selectedCompany.id}/${cupom}/${cpf}`,
        {
          params: {
            idEmpresa: selectedCompany.id,
            CodigoCupom: cupom,
            Cpf: cpf.replace(/\D/, ''),
          },
        },
      );

      if (response.data?.sucesso === true) {
        setIsLoading(false);
        changeIsmodalDiscontVisible(false);
        changeIsModalReviewVisible(true);
        setDataDesconto(response.data?.dados)
        setErrorMessage(response.data?.mensagem);
      } if (response.data?.sucesso === false) {
        setErrorMessage(response.data?.mensagem);
        setIsLoading(false);

      }
    } catch (error: any) {
      if (!error.response.data.sucesso)
        if(error.response.data.mensagem === 'Cupom Inválido ou Não encontrado!')
          setErrorMessage('Cupom Inválido!');
        else if(error.response.data.mensagem === "O usuário usou este cupom  o número máximo de vezes estipulado.")
          setErrorMessage('Uso do cupom excedido.');
        else
          setErrorMessage(error.response.data.mensagem);
    }
      setIsLoading(false);
  }


  const handleCancel = () => {
    changeIsmodalDiscontVisible(false)
    console.log(dataDesconto, (Object.keys(dataDesconto||{})?.length === 0))
    if (Object.keys(dataDesconto||{})?.length === 0) {
      setCpf('')
      setCupom('')
      setErrorMessage('')
      setMessageErrorCpf('')
    } else {
      setCupom(localStorage.getItem('cupom_storage')||"")
      setCpf(localStorage.getItem('cpf_storage')||"")

    }
  }

  return (
    <ContainerModal>
      <Container>
        <h1>Informe seu Cupom e CPF para conseguir seu desconto</h1>
        <form>
          <input
            id='cupomInput'
            value={cupom}
            onFocus={() => setIsKeyboard(true)}
            onChange={(e) => setCupom(e.target.value)}
            placeholder="Insira o cupom de desconto"
          />
        </form>
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>
        <form>
          <InputMask
            id='cpfInput'
            type={show ? 'text' : 'password'}
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            onFocus={() => setIsKeyboard(false)}
            mask="999.999.999-99"
            maskChar={null}
            placeholder="Insira o seu CPF"
            maxLength={14}
          />
          <button
            type='button'
            onClick={() => setShow(!show)}
            style={
              {
                background: 'transparent',
                padding: '0 1rem',
                border: 'none',
                color: theme.colors.DefaultColor,
              }
            }
          >
            {!show ? <VscEyeClosed size={40} /> : <VscEye size={40} />}
          </button>
        </form>
        <ErrorMessage>
          {messageErrorCpf}
        </ErrorMessage>
        <footer>
          <button type="button" onClick={handleCancel}>
            Cancelar
          </button>
          <button type="button" disabled={isLoading} onClick={sendCode}>
            Continuar
          </button>
        </footer>
      </Container>
      <KeyboardContainer>
        <div
          style={{
            display: isKeyboard?undefined:'none',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <StyledKeyboard
            // eslint-disable-next-line no-return-assign
            keyboardRef={(r) => (keyboard.current = r)}
            inputName="cupomInput"
            layoutName={layoutName}
            onKeyPress={(e: any) => onKeyPress(e)}
            onChange={(e: any) => {
              setCupom(e);
              keyboard.current?.setInput(e);
            }}
            className="keyboard"
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - {bksp}',
                'q w e r t y u i o p [ ] \\',
                "a s d f g h j k l ; '",
                '{shift} z x c v b n m , . /',
                '@ {space}',
              ],
              shift: [
                '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
                'Q W E R T Y U I O P { } |',
                'A S D F G H J K L : "',
                '{shift} Z X C V B N M &lt; &gt; ',
                '{space}',
              ],
            }}
            display={{
              '{bksp}': '  <------  ',
              '{shift}': 'Shift',
              '{space}': ' ',
            }}
          />
        </div>
        <NumberKeyboard
          isKeyboard={!isKeyboard}
        >
          <section>
            <button type="button" onClick={() => addIdCpfCode('7')}>
              7
            </button>
            <button type="button" onClick={() => addIdCpfCode('8')}>
              8
            </button>
            <button type="button" onClick={() => addIdCpfCode('9')}>
              9
            </button>
          </section>
          <section>
            <button type="button" onClick={() => addIdCpfCode('4')}>
              4
            </button>
            <button type="button" onClick={() => addIdCpfCode('5')}>
              5
            </button>
            <button type="button" onClick={() => addIdCpfCode('6')}>
              6
            </button>
          </section>
          <section>
            <button type="button" onClick={() => addIdCpfCode('1')}>
              1
            </button>
            <button type="button" onClick={() => addIdCpfCode('2')}>
              2
            </button>
            <button type="button" onClick={() => addIdCpfCode('3')}>
              3
            </button>
          </section>
          <section>
            <button type="button" onClick={() => addIdCpfCode('0')}>
              0
            </button>
            <button type="button" onClick={() => addIdCpfCode('delete')}>
              <RiDeleteBack2Fill />
            </button>
            <button type='button' disabled> </button>
          </section>
        </NumberKeyboard>
      </KeyboardContainer>
    </ContainerModal>
  );
}
