import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isLight()
      ? darken(0.2, theme.colors.Background)
      : lighten(0.15, theme.colors.Background)};
  padding: 20px;
  border-radius: 0.5rem;
  h1 {
    font-weight: 800;
    font-size: 2.8rem;
    text-align: center;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(1, theme.colors.Background)
        : lighten(1, theme.colors.Background)};
  }
`;

export const ButtonContainer = styled.div<ButtonProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${({ isGoBack }) => isGoBack && 'justify-content: center;'}
`;

interface ButtonProps {
  isLast?: boolean;
  isGoBack?: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  font-family: Poppins;
  font-weight: 800;
  ${({ isLast }) => isLast && 'margin-left: 10px;'}
  color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isLight()
      ? darken(0.9, theme.colors.Background)
      : lighten(0.9, theme.colors.Background)};
  background: ${({ theme }) => theme && theme.colors.DefaultColor};
  ${({ isGoBack, theme }) =>
    isGoBack &&
    `
    width: 50%;
    color: ${theme.colors.DefaultColor};
    background: ${
      tinycolor(theme.colors.Background).isLight()
        ? darken(0.9, theme.colors.Background)
        : lighten(0.9, theme.colors.Background)
    };`}
  cursor: pointer;
`;
