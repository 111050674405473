import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Footer = styled.footer`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  background: ${({ theme }) => theme.colors.Background};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &:first-child {
      margin: 1rem 0;
    }

    section {
      border: 1px solid
        ${({ theme }) =>
          tinycolor(theme.colors.Background).isLight()
            ? darken(0.2, theme.colors.DefaultColor)
            : lighten(0.2, theme.colors.DefaultColor)};
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16rem;
      padding: 0.625rem 1.25rem;
      background-color: ${({ theme }) => theme.colors.Background} !important;
      @media screen and (min-width: 900px) and (max-width: 1420px) {
        button {
          font-size: 1rem;
          width: 1.5rem;
        }
        width: 10rem;
        height: 4rem;
        padding: 0 1rem;
      }

      button {
        background-color: ${({ theme }) => theme.colors.Background};
        color: ${({ theme }) =>
          theme && theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.8, theme.colors.Background)
            : lighten(0.8, theme.colors.Background)};
        display: flex;
        align-items: center;
        border: 0;
        font-size: 3rem;

        &:disabled {
          filter: opacity(0.5);
          cursor: not-allowed;
        }
      }

      input {
        font-family: 'Poppins';
        height: 100%;
        width: 100%;
        border: 0;
        @media screen and (min-width: 900px) and (max-width: 1420px) {
          font-size: 2rem;
        }
        font-size: 3rem;
        font-weight: 700;
        background-color: ${({ theme }) => theme.colors.Background};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.8, theme.colors.Background)
            : lighten(0.8, theme.colors.Background)};
      }
    }

    span {
      font-family: 'Poppins';
      font-size: 4rem;
      font-weight: bold;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};

      @media screen and (min-width: 900px) and (max-width: 1420px) {
        font-size: 2rem;
      }
    }

    h1 {
      font-family: 'Poppins';
      font-size: 3rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
      font-weight: bold;
    }

    &:last-child {
      margin-top: 1rem;
    }

    &:last-child > button {
      height: 8rem;
      flex: 1;
      border: 0;
      font: 2.25rem 'Poppins';
      padding: 0.5rem;
      border-radius: 1rem;

      @media screen and (min-width: 900px) and (max-width: 1420px) {
        height: 4rem;
        font-size: 1.5rem;
      }

      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.1, theme.colors.Background)
          : lighten(0.1, theme.colors.Background)};

      color: ${({ theme }) =>
        theme && theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
      font-weight: 700;

      &:first-child {
        margin-right: 0.95rem;
        background: transparent;
        color: ${({ theme }) => theme && theme.colors.DefaultColor};
        border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
      }

      &:last-child {
        background: ${({ theme }) => theme && theme.colors.DefaultColor};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.DefaultColor).isLight()
            ? darken(0.9, '#ffffff')
            : lighten(0.9, '#ffffff')};
        border: 0;
      }

      &:last-child:disabled {
        filter: opacity(0.5);
        cursor: not-allowed;
      }
    }

    .prices {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .oldPrice {
      font-size: 1.3rem;
      text-decoration: line-through;
      //color: #ececec;
    }
  }
`;
