import styled, { css } from 'styled-components';

interface GridProps {
  isOdd: boolean;
}

export const Container = styled.div`
  font-family: Poppins;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  overflow: scroll;
  height: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    flex: 1;
    margin-top: 2rem;

    h1 {
      font-family: Poppins;
      font-weight: 400;
      font-size: 4.5rem;
      text-align: center;
      color: #242424;
      animation-name: fadeIn;
      opacity: 0;
      animation-duration: 0.25s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    & > section {
      display: flex;
      justify-content: center;
      margin: 1.5rem 0;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.75rem;
        background: #fed8b1;
        font-size: 2.5rem;
        color: #242424;

        span {
          font-family: Poppins;
          font-size: 2rem;
          color: #ff9e1b;
        }

        svg {
          margin: 0 0.5rem;
        }
      }
    }
  }
`;

export const Grid = styled.div<GridProps>`
  margin-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  justify-items: center;
  align-items: center;

  ${(props) =>
    props.isOdd &&
    css`
      button:last-child {
        grid-column: 2 / span 2;
      }
    `}
`;
