import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

interface ContentProps {
  isOdd: boolean;
}

interface AvisoProps {
  isAviso: boolean;
}

export const Aviso = styled.strong<AvisoProps>`
  ${({ isAviso }) =>
    !isAviso &&
    css`
      display: none;
    `};

  padding-top: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme && theme.colors.SecondaryColor};
`;

export const Container = styled.main`
  background-color: ${({ theme }) => theme && theme.colors.Background};
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Animation = styled.div`
  height: 100%;
  width: 100%;

  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<ContentProps>`
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Heinberg;

  button {
    margin-top: 5rem;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    align-self: flex-start;
    font-size: 8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      flex: 1;

      color: ${({ theme }) => theme && theme.colors.SecondaryColor};
    }
  }

  #resumo_pedido {
    width: 100%;
    justify-content: start;
    text-align: left;
    h1 {
      font-size: 5rem;
      font-weight: 700;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      margin-top: 2rem;

      @media screen and (max-width: 768px) {
        font-size: 4.5rem;
      }
    }

    div {
      display: flex;
      justify-content: space-between;

      span {
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.9, theme.colors.Background)
            : lighten(0.9, theme.colors.Background)};
        font-size: 4rem;
        &:last-child {
          font-weight: 700;
        }
      }
    }
  }

  div:last-child {
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
    width: 100%;
    h1 {
      font-size: 4rem;
      font-weight: 700;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};

      margin-top: 2rem;

      @media screen and (max-width: 768px) {
        font-size: 3.5rem;
      }
    }

    div {
      /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;*/
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      button {
        margin-top: 1.5rem;
        width: 100%;
        font-weight: 600;
        //grid-column: span 2;
        display: flex;
        justify-content: center;
        padding: 2rem;
        border: 0;
        border-radius: 1.5rem;
        color: ${({ theme }) => theme && theme.colors.DefaultColor};
        background: transparent;
        border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};

        font-size: 5rem;
        font-family: Poppins;
        :disabled {
          filter: opacity(0.5);
          cursor: not-allowed;
        }

        /*  ${({ isOdd }) =>
          isOdd &&
          css`
            &:last-child {
              grid-column: 2 / span 2;
            }
          `} */

        :hover {
          background: ${({ theme }) =>
            theme && tinycolor(theme.colors.Background).isLight()
              ? darken(0.1, theme.colors.SecondaryColor)
              : lighten(0.1, theme.colors.SecondaryColor)};
        }
      }
    }
  }
`;

export const TextButton = styled.strong`
  display: block;
  font-size: 2rem;
  font-weight: 700;
`;
