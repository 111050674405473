/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prettier/prettier */
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Touch from '../assets/TouchD.png';
import { CompanyContext } from '../contexts/CompanyContext';
import api from '../services/api';
import {
  loadingBannerElectron,
  loadingLogoElectron
} from '../utils/imagensFront';

export function useMain():any {
  const {
    loadCompanies,
    setIsOrderFinalized,
    setIsLoading,
    setIsModalLoginVisible,
    setTotemName,
    totemName,
    setRequestNumberMobile,
    setTotemNeedPayment,
    totemID,
    setTotemID,
    setIsAdminEmpresa,
    serviceElectron,
    setLinkLoading,
    isModalLoginVisible,
    totemMaintenance,
    setTotemMaintenance,
  } = useContext(CompanyContext);

  const [imgs, setImgs] = useState<string[]>([]);
  const [logo, setLogo] = useState('');
  const [logoPe, setLogoPe] = useState(false);
  const [logoPd, setLogoPd] = useState(true);
  const [logoPor, setLogoPor] = useState(0);
  const [intervalId, setIntervalId] = useState<number>();

  useEffect(() => {
    setIsOrderFinalized(true);
    if(!navigator.onLine) return;
    if (!totemID) logins();
    ApiBannerLogo();
    loadingBannerLogoInterval();
  }, []);

  const handleStart = async () => {
    const dataExpire = window.localStorage.getItem('dataExpire') || '';
    if(dayjs().add(30,'minute').isAfter(dayjs(dataExpire)) || dataExpire === '')
      await logins();
    if (!isModalLoginVisible) {
      setIsOrderFinalized(false);
      await loadCompanies();
    }
    if (intervalId) clearInterval(intervalId);
  };

  const ApiBannerLogo = async () => {
    if (serviceElectron.isElectronApp) {
      await loadingLogoElectron(
        { setLogo, setLogoPe, setLogoPd, setLogoPor },
        serviceElectron,
      );
      await loadingBannerElectron(setImgs, serviceElectron);
    } else {
      setLogoPd(true);
      setLogo(Touch);
    }
  }

  const loadingBannerLogoInterval = async () => {
    const interval = setInterval(async () => {
      if ([0, 15, 30, 45].includes(new Date().getMinutes()))
        await ApiBannerLogo();
    }, 1000 * 60);
    setIntervalId(interval);
  }

  useEffect(() => {
    async function loadTotemName() {
      if (serviceElectron?.isElectronApp) {
        try {
          setIsLoading(true);
          const result = await serviceElectron?.ipcRenderer?.invoke(
            'infoTotem',
          );
          if (result.Sucesso) setTotemName(result.Msg);
        } catch (err) {
          setIsLoading(false);
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    }

    loadTotemName();
  }, [setIsLoading, setTotemName, totemName]);

  async function logins(){
    if (serviceElectron.isElectronApp || localStorage.getItem('totemLogin')) {
      try {
        setIsLoading(true);
        let response;
        if (localStorage.getItem('totemLogin') && !serviceElectron.isElectronApp) {
        const { userName, password } = JSON.parse(
            localStorage.getItem('totemLogin') || '{}',
        );
        const bodyRequest = {
            userName,
            password: Buffer.from(password, 'base64').toString('utf-8'),
        };
        const respAPI = await api.post('api/v1/users/login', bodyRequest, {
            withCredentials: true,
        });
        response = respAPI.data;
        } else response = await serviceElectron?.ipcRenderer?.invoke('login');

        if (!response) {
          alert('Erro na autenticação do terminal! Verifique usuário/senha ou entre em contato com o administrador.');
        }
        const {
            expiration,
            token,
            solicitaTelefone,
            user: { solicitaPagto, userName, id, painelSenha, totem, adminEmpresa },
            totem: { totemMobile,  loadingLink, thisMaintenance},
            } = response;
        if (!painelSenha && !totemMobile && totem) {
          window.localStorage.setItem('dataExpire', expiration)
          window.localStorage.setItem('user', userName);
          window.localStorage.setItem('token', token);
          setLinkLoading(loadingLink);
          setTotemID(id);
          setTotemMaintenance(thisMaintenance)
          setIsAdminEmpresa(adminEmpresa);
          setRequestNumberMobile(solicitaTelefone);
          setTotemNeedPayment(solicitaPagto);
          setIsOrderFinalized(false);
        } else {
          window.localStorage.removeItem('user');
          window.localStorage.removeItem('token');
          alert('Erro na autenticação do terminal! Verifica se usuário é tipo Totem.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else if (!serviceElectron.isElectronApp)
      setIsModalLoginVisible(true);
  }

  return {
    imgs,
    logo,
    logoPe,
    logoPd,
    logoPor,
    handleStart,
    totemName,
    totemID,
    isModalLoginVisible
  };
}
