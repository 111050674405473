import styled, { css, keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

interface ContainerProps {
  hasQuestions: boolean;
  hasObservacao: boolean;
}

const fadeInBottom = keyframes`
  ${'0%'} {
    opacity: 0;
    margin-top: 10rem;
  }
  ${'100%'} {
    opacity: 1;
    margin-top: 0;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:last-child {
    padding: 1.5rem;
    border-radius: 1.5rem;

    max-height: 85vh;
    width: 85vw;
    @media screen and (min-width: 900px) and (max-width: 1420px) {
      padding: 1rem;
      max-height: 95vh;
      width: 85vw;
    }
    border: 0;
    animation-name: ${fadeInBottom};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    display: grid;
    grid-template-columns: 1fr;
    background: ${({ theme }) => theme && theme.colors.Background};
    ${({ theme }) =>
      tinycolor(theme.colors.Background).isDark() &&
      css`
        border: 1px solid;
        border-color: ${theme.colors.SecondaryColor};
      `};

    ${({ hasQuestions, hasObservacao }) =>
      // eslint-disable-next-line no-nested-ternary
      hasQuestions || hasObservacao
        ? css`
            grid-template-rows: auto minmax(5%, 65%) auto;
            grid-template-areas: 'header' 'items' 'footer';
          `
        : css`
            //min-height: 70%;
            grid-template-rows: auto auto;
            grid-template-areas: 'header' 'footer';
          `}

    @media screen and (orientation: landscape) {
      width: 50%;
      height: 90%;
    }
  }

  @keyframes slide-right {
    0% {
      margin-right: 100%;
    }
    100% {
      margin-right: 0%;
    }
  }
`;
