import { Dispatch, SetStateAction } from 'react';
// eslint-disable-next-line import/no-unresolved
import Touch from '../assets/TouchD.png';
import { ElectronService } from '../lib/electron.service';

interface loadingLogoType {
  setLogo: (value: string) => void;
  setLogoPd: (value: boolean) => void;
  setLogoPe: (value: boolean) => void;
  setLogoPor: Dispatch<SetStateAction<number>>;
}

export async function loadingLogo({
  setLogo,
  setLogoPd,
  setLogoPe,
  setLogoPor,
}: loadingLogoType): Promise<void> {
  try {
    const result = await window.bound.logoApi();
    if (result.Sucesso) {
      const resultDates = result.Dados;
      if (resultDates != null && resultDates.verificado) {
        setLogo(
          `https://touch-img.s3.sa-east-1.amazonaws.com/app-images/${resultDates.logo}`,
        );
        setLogoPd(false);
        if (resultDates.logope && resultDates.verificado) {
          setLogoPe(true);
          setLogoPor(resultDates.porcento);
        }
      } else {
        setLogo(Touch);
        setLogoPd(true);
      }
    } else {
      setLogo(Touch);
      setLogoPd(true);
    }
  } catch (error) {
    setLogo(Touch);
    setLogoPd(true);
  }
}

export async function loadingBanner(
  setImgs: (value: string[]) => void,
): Promise<void> {
  try {
    const result = await window.bound.bannerApi();
    if (result.Sucesso) {
      const resultDates = JSON.parse(result.Dados);
      const newImgs = resultDates.map(
        (img: any) =>
          `https://touch-img.s3.sa-east-1.amazonaws.com/app-images/${img}`,
      );
      if (Object.keys(newImgs).length !== 0) setImgs(newImgs);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function loadingBannerElectron(
  setImgs: (value: string[]) => void,
  service: ElectronService,
): Promise<void> {
  try {
    const result = await service?.ipcRenderer?.invoke('bannerApi');
    if (result.Sucesso) {
      const resultDates = result.Dados;
      if (Object.keys(resultDates).length !== 0) setImgs(resultDates);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function loadingLogoElectron(
  { setLogo, setLogoPd, setLogoPe, setLogoPor }: loadingLogoType,
  service: ElectronService,
): Promise<void> {
  try {
    const result = await service?.ipcRenderer?.invoke('logoApi');
    if (result.Sucesso) {
      const resultDates = result.Dados;
      if (resultDates != null && resultDates.verificado) {
        setLogo(resultDates.logo);
        setLogoPd(false);
        if (resultDates.logope && resultDates.verificado) {
          setLogoPe(true);
          setLogoPor(resultDates.porcento);
        }
      } else {
        setLogo(Touch);
        setLogoPd(true);
      }
    } else {
      setLogo(Touch);
      setLogoPd(true);
    }
  } catch (error) {
    setLogo(Touch);
    setLogoPd(true);
  }
}
