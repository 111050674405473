import EmptyProduct from '../../../assets/empty-product.png';
import { Item } from './styles';

interface CreditItemProps {
  selectProduct: (id: number) => void;
  product: {
    idProduto: number;
    nome: string;
    imagem: string;
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CreditItem({ selectProduct, product }: CreditItemProps) {
  return (
    <Item>
      <button type="button" onClick={() => selectProduct(product.idProduto)}>
        <div>
          <img
            src={product.imagem !== '' ? product.imagem : EmptyProduct}
            alt={product.nome}
          />
        </div>
        <strong>{product.nome}</strong>
      </button>
    </Item>
  );
}
