import { createGlobalStyle } from 'styled-components';

interface GlobalProps {
  theme: {
    primaryColor: string;
  };
}

export default createGlobalStyle<GlobalProps>`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    scrollbar-width: none;
    scrollbar-color: none;
  }

  html {
    scroll-behavior: smooth;
    touch-action: none;
    overscroll-behavior: none !important;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none !important;

  }

  body, input, button, span {
    font: 1rem Poppins;
    //color: ${({ theme }) => theme && theme.primaryColor};
  }

  strong {
    //color: ${({ theme }) => theme && theme.primaryColor};
  }

  html, body, #root, #root>div {
    height: 100%
  }

  button {
    cursor: pointer;
  }

  @media(max-height: 1366px) {
    html {
      font-size: 81.25%;
    }
  }

  @media(max-height: 1080px) {
    html {
      font-size: 62.5%;
    }
  }

  @media(max-height: 720px) {
    html {
      font-size: 81.25%;
    }
  }
`;
