import { IoArrowBackCircle } from 'react-icons/io5';
import { AlertDialog } from '../../components/AlertDialog';
import { ModalPayment } from '../../components/ModalPayment';
// import { LastOrder, PaymentContext } from '../../contexts/PaymentContext';
import { NumberFormat } from '../../components/NumberFormat';
import { useChoosePaymentMethod } from '../../hook/useChoosePaymentMethod';
import { Aviso, Container, Content, TextButton } from './styles';

export function ChoosePaymentMethod(): JSX.Element {
  const {
    goBack,
    isModalPaymentOpen,
    errorMessage,
    retryOrder,
    cancelOrder,
    isAlertDialogOpen,
    setIsAlertDialogOpen,
    paymentMethods,
    confirmOrder,
    orderPrice,
    msgAviso,
    isAviso,
    isLoading,
  } = useChoosePaymentMethod();

  return (
    <Container>
      {isModalPaymentOpen && (
        <ModalPayment
          message={errorMessage}
          retry={retryOrder}
          cancel={cancelOrder}
        />
      )}
      {isAlertDialogOpen && (
        <AlertDialog onClose={() => setIsAlertDialogOpen(false)} />
      )}
      <Content isOdd={paymentMethods.length % 2 !== 0}>
        <button type="button" onClick={goBack}>
          <IoArrowBackCircle />
        </button>

        <div id="resumo_pedido">
          <h1>Resumo do pedido:</h1>
          <div>
            <span>Valor total:</span>
            <NumberFormat prefix="R$">{orderPrice}</NumberFormat>
          </div>
        </div>

        <div>
          <h1>Método de pagamento:</h1>
          <Aviso key="Aviso_erro" isAviso={isAviso}>
            {msgAviso}
          </Aviso>
          <div>
            {paymentMethods
              .sort((a, b) => a.formaPagamento - b.formaPagamento)
              .map((method) => (
                <>
                  <button
                    key={method.id}
                    type="button"
                    onClick={() => confirmOrder(method.formaPagamento)}
                    disabled={
                      (method.descricao === 'PIX' && orderPrice < 1.0) ||
                      isLoading
                    }
                  >
                    {method.descricao}
                    {method.descricao === 'PIX' && orderPrice < 1.0 && (
                      <TextButton>Desativado</TextButton>
                    )}
                  </button>
                </>
              ))}
          </div>
        </div>
      </Content>
    </Container>
  );
}
