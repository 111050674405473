import styled, { css, keyframes } from 'styled-components';
import tinycolor from 'tinycolor2';

type LoadingProps = {
  isOpen: boolean;
};

export const Container = styled.div<LoadingProps>`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 100;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: none;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s linear;

    div:last-child {
      display: flex;
      align-items: baseline;

      span {
        color: ${({ theme }) => theme.colors.DefaultColor};
        font-family: Poppins;
        font-weight: 600;
        font-size: 2rem;
        margin-right: 1.5rem;
      }
    }
  }

  ${({ isOpen }) =>
    !isOpen
      ? css`
          display: none;

          & > div {
            opacity: 0;
          }
        `
      : css`
          display: flex;

          & > div {
            opacity: 1;
          }
        `}
`;

const dotFalling = (theme) => keyframes`
  ${'0%'} {
      box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
  ${'25%'},
  ${'50%'},
  ${'75%'} {
    box-shadow: 9999px 0 0 0 ${theme.colors.DefaultColor};
  }
  ${'100%'} {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
`;

const dotFallingBefore = (theme) => keyframes`
  ${'0%'} {
      box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
  ${'25%'},
  ${'50%'},
  ${'75%'} {
    box-shadow: 9984px 0 0 0 ${theme.colors.DefaultColor};
  }
  ${'100%'} {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
`;

const dotFallingAfter = (theme) => keyframes`
  ${'0%'} {
      box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
  ${'25%'},
  ${'50%'},
  ${'75%'} {
    box-shadow: 10014px 0 0 0 ${theme.colors.DefaultColor};
  }
  ${'100%'} {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
`;

export const ThreeDots = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.DefaultColor};
  box-shadow: 9999px 0 0 0 ${({ theme }) => theme.colors.DefaultColor};
  animation: ${({ theme }) => dotFalling(theme)} 1s infinite linear;
  animation-delay: 0.1s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.DefaultColor};
    animation: ${({ theme }) => dotFallingBefore(theme)} 1s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.DefaultColor};
    animation: ${({ theme }) => dotFallingAfter(theme)} 1s infinite linear;
    animation-delay: 0.2s;
  }
`;
