import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 1)'
      : 'rgba(225, 225, 225, 1)'};
  padding: 20px;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid
    ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  max-width: 32rem;
`;

export const FloatMessage = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.65rem;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 1)'
      : 'rgba(225, 225, 225, 1)'};
  border-radius: 0.3125rem;
  box-shadow: 0px 0.64673912525177px 0.64673912525177px 0px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.64673912525177px
      ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isDark()
          ? 'rgba(0, 0, 0, 0.6)'
          : 'rgba(0, 0, 0, 0.8)'},
    0px 1.29347825050354px 3.2336955070495605px 0px rgba(103, 110, 118, 0.08),
    0px 0px 0px 2.58695650100708px
      ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isDark()
          ? 'rgba(0, 0, 0, 0.6)'
          : 'rgba(0, 0, 0, 0.8)'};
  color: ${({ theme }) => theme && theme.colors.DefaultColor};
  div {
    margin: 0 1rem;
    h1 {
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 0.64rem;
    }
    span {
      font-size: 1.5rem;
      font-weight: 400;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
    }
  }
  svg:last-child {
    margin-left: auto;
  }
`;

export const Body = styled.div`
  margin-top: 1rem;
  text-align: center;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  h1 {
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  div {
    display: flex;
    margin: 0.5rem 0;
    justify-content: center;
    img {
      max-width: 20rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: auto;
    max-height: 6rem;
    :first-child {
      height: 3rem;
    }
    :last-child {
      margin-left: auto;
    }
  }
`;
