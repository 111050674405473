import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;
    max-width: 70%;

    padding: 2rem;

    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background-color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.15, theme.colors.Background)
        : lighten(0.15, theme.colors.Background)};
    align-items: center;
    justify-content: center;

    svg {
      font-size: 10rem;
      color: ${({ theme }) => theme && theme.colors.SecondaryColor};
    }

    h1 {
      text-align: center;
      margin-top: 1rem;
      font-size: 3rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
    }

    button {
      margin-top: 1.5rem;
      padding: 1rem;
      width: 100%;

      border: 0;
      border-radius: 0.5rem;

      font: 4rem Heinberg;
      background: ${({ theme }) => theme && theme.colors.DefaultColor};
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.DefaultColor).isLight()
          ? darken(0.2, theme.colors.SecondaryColor)
          : lighten(0.2, theme.colors.SecondaryColor)};
    }
  }
`;
