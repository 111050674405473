import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
