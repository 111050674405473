import BaseNumberFormat from 'react-number-format';

interface NumberFormatProps {
  children: string | number;
  prefix: string;
  suffix?: string;
  className?: string;
  hidden?: boolean;
}

export function NumberFormat({
  className,
  hidden,
  children,
  prefix,
  suffix = '',
}: NumberFormatProps): JSX.Element {
  return (
    <BaseNumberFormat
      className={className}
      hidden={hidden}
      value={children}
      displayType="text"
      prefix={prefix}
      suffix={suffix}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator="."
      decimalSeparator=","
    />
  );
}
