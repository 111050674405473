import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CheckoutContext } from '../../contexts/CheckoutContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { ModalContext } from '../../contexts/ModalContext';
import { ModalReviewContext } from '../../contexts/ModalReviewContext';
import { Checkout } from './Checkout';
import { MenuContent } from './MenuContent';
import { MenuList } from './MenuList';
import { Modal } from './Modais/Modal';
import { ModalDiscont } from './Modais/ModalDiscont';
import { ModalReview } from './Modais/ModalReview';
import { Grid, ToastContainerTotem } from './styles';

export function Menu(): JSX.Element {
  const { isModalVisible } = useContext(ModalContext);
  const { selectedCompany } = useContext(CompanyContext);
  const { isModalReviewVisible, isModalDiscontVisible } =
    useContext(ModalReviewContext);
  const { lastOrder } = useContext(CheckoutContext);

  const history = useHistory();

  if (Object.keys(selectedCompany).length === 0) history.push('/');

  // console.log('valida');
  /* let button;
  if (selectedCompany.id)
    button ;
   else {
    button = (
      <GoBackContainer onClick={() => history.push('/')}>
        <button type="button">
          {selectedCompany.id !== 8 ? 'Impressora S/ Papel' : 'Voltar'}
        </button>
      </GoBackContainer>
    );
  } */

  useEffect(() => {
    if (Object.keys(lastOrder).length !== 0) history.push('/success');
  }, [lastOrder]);

  return (
    <>
      {/* {codebarras()} */}
      <Grid readOnlyMenu={selectedCompany.id === 8}>
        <MenuList />
        <MenuContent />
        <Checkout />
        {isModalVisible && <Modal />}
        {isModalReviewVisible && <ModalReview />}
        {isModalDiscontVisible && <ModalDiscont />}
        <ToastContainerTotem
          position="top-center"
          autoClose={5000}
          newestOnTop={false}
          closeButton={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Grid>
    </>
  );
}

export default Menu;
