import { FaExclamationCircle } from 'react-icons/fa';
import { Container } from './styles';

interface ModalMensageProps {
  isOpen: boolean;
  imprimir: () => void;
  notimprimir: () => void;
}
export function ModalComprovante({
  isOpen,
  imprimir,
  notimprimir,
}: ModalMensageProps): JSX.Element {
  return (
    <Container isOpen={isOpen}>
      <div>
        <FaExclamationCircle />
        <strong>Comprovante de pagamento</strong>
        <h1>Deseja imprimir o comprovante de pagamento?</h1>
        <div>
          <button type="button" onClick={notimprimir}>
            Não Imprimir
          </button>
          <button type="button" onClick={imprimir}>
            Sim, imprimir
          </button>
        </div>
      </div>
    </Container>
  );
}
