import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  z-index: 1;
  padding: 0 1rem;
  position: relative;
  @media screen and (min-width: 900px) and (max-width: 1420px) {
    height: 4rem;
  }

  & + li:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 100%; /* or 100px */
    border-bottom: 1px solid #dadad9;
  }

  div:first-child {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 900px) and (max-width: 1420px) {
      flex-direction: row;
      gap: 1rem;
    }

    span {
      font-family: 'Poppins';
      font-size: 1.75rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
      @media screen and (min-width: 900px) and (max-width: 1420px) {
        font-size: 1.25rem;
      }
    }

    span:nth-child(2) {
      font-family: 'Poppins';
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
      font-size: 1.25rem;
      margin-top: 0.35rem;
      @media screen and (min-width: 900px) and (max-width: 1420px) {
        font-size: 1rem;
      }
    }
  }

  div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid #ececec;
    //border-radius: 4px;

    button {
      background-color: ${({ theme }) => theme.colors.Background};
      color: ${({ theme }) => theme.colors.DefaultColor};
      height: 3.5rem;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      border: 0;
      font-size: 2rem;

      @media screen and (min-width: 900px) and (max-width: 1420px) {
        height: 2.5rem;
        padding: 0rem 0.5rem;
        font-size: 1rem;
      }
    }

    button:disabled {
      filter: opacity(0.5);
      cursor: not-allowed;
    }
  }

  input {
    background-color: ${({ theme }) => theme.colors.Background};
    font-family: 'Poppins';
    width: 2.5rem;
    flex: 1;
    color: ${({ theme }) => theme && theme.colors.DefaultColor};
    border: 0;
    font-size: 2rem;

    @media screen and (min-width: 900px) and (max-width: 1420px) {
      width: 2rem;
      font-size: 1.5rem;
    }
  }
`;
