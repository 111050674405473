import { lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Item = styled.li`
  width: 100%;
  height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  grid-column: span 2;
  max-width: 22rem;

  &:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }

  button {
    width: 100%;
    height: 100%;
    flex: 1;
    border: 0;
    background: ${({ theme }) => theme && theme.colors.Background};
    border: 1px solid
      ${(props) =>
        tinycolor(props.theme.colors.Background).isLight()
          ? '#dbdbdb'
          : '#332F2E'};
    border-radius: 8%;
    padding: 1.25rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      height: 10rem;
      width: 10rem;
      border-radius: 0.25rem;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 0.25rem;
      }
    }

    strong {
      margin-top: 1rem;
      font-size: 2rem;
      color: ${(props) =>
        tinycolor(props.theme.colors.Background).isLight()
          ? '#262626'
          : lighten(0.8, '#262626')};
    }
  }
`;
