import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  // eslint-disable-next-line prettier/prettier
  useState
} from 'react';
import api from '../services/api';
import { CompanyContext } from './CompanyContext';
import { MenuListContext } from './MenuListContext';

interface SubgroupContextData {
  subGroups: GroupSubGroup[];
  selectedSubgroup: GroupSubGroup;
  selectSubgroup(id: number): void;
}

interface SubgroupProviderProps {
  children: ReactNode;
}

interface GroupSubGroup {
  idSubGrupo: number;
  idGrupo: number;
  ordem: number;
  exibir: boolean;
  subGrupo: SubGroup;
}

interface SubGroup {
  nome: string;
  imagem: string;
  banner: string;
}

export const SubgroupContext = createContext({} as SubgroupContextData);

export function SubgroupProvider({ children }: SubgroupProviderProps) {
  const [subGroups, setSubGroups] = useState<GroupSubGroup[]>([]);
  const [selectedSubgroup, setSelectedSubgroup] = useState({} as GroupSubGroup);

  const {
    selectedCompany,
    isOrderFinalized,
    setIsLoading,
    baseUrl,
    serviceElectron,
  } = useContext(CompanyContext);

  const { selectedGroup } = useContext(MenuListContext);

  useEffect(() => {
    async function loadContent() {
      try {
        setIsLoading(true);
        setSubGroups([]);

        const uriSubGroup = `api/v1/GrupoSubGrupo/GetSubMenu/${selectedCompany.id}/${selectedGroup.idGrupo}`;
        const response = await api.get(uriSubGroup);
        const responseSubGrupo = response.data;
        // eslint-disable-next-line no-restricted-syntax
        for (const sub of responseSubGrupo) {
          // eslint-disable-next-line no-restricted-syntax
          const index = responseSubGrupo.findIndex(
            (x: any) => x.idSubGrupo === sub.idSubGrupo,
          );

          if (sub.subGrupo?.imagem) {
            let logo = `${baseUrl}${sub.subGrupo.imagem}`;
            if (serviceElectron?.isElectronApp) {
              // eslint-disable-next-line no-await-in-loop
              const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
                'getImage',
                sub.subGrupo.imagem,
                false,
                '',
              );
              if (responseSmart.Sucesso) {
                logo = responseSmart.Msg;
              }
            }
            responseSubGrupo[index].subGrupo.imagem = logo;
          }

          if (sub.subGrupo?.banner !== null && sub.subGrupo?.banner !== '') {
            let banner = `${baseUrl}${sub.subGrupo?.banner}`;
            if (serviceElectron?.isElectronApp) {
              // eslint-disable-next-line no-await-in-loop
              const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
                'getImage',
                sub.subGrupo?.banner,
                false,
                '',
              );
              if (responseSmart.Sucesso) {
                banner = responseSmart.Msg;
              }
            }
            responseSubGrupo[index].subGrupo.banner = banner;
          }
        }
        setSubGroups(responseSubGrupo);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (
      Object.keys(selectedGroup).length === 0 ||
      !selectedGroup.possuiSubGrupo
    )
      return;
    loadContent();
  }, [selectedCompany, selectedGroup, setIsLoading]);

  useEffect(() => {
    if (!isOrderFinalized) return;

    setSubGroups([]);
    setSelectedSubgroup({} as GroupSubGroup);
  }, [isOrderFinalized]);

  function selectSubgroup(id: number) {
    const selected =
      subGroups.find((subGroup) => subGroup.idSubGrupo === id) ||
      ({} as GroupSubGroup);

    setSelectedSubgroup(selected);
  }

  return (
    <SubgroupContext.Provider
      value={{ subGroups, selectedSubgroup, selectSubgroup }}
    >
      {children}
    </SubgroupContext.Provider>
  );
}
