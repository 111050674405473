/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CompanyProvider } from './contexts/CompanyContext';
import { Routes } from './routes';
import GlobalStyle from './styles/global';

export function App(): JSX.Element {
  return (
    <>
      <BrowserRouter>
        <CompanyProvider>
          <GlobalStyle />
          <Routes />
        </CompanyProvider>
      </BrowserRouter>
    </>
  );
}
