/* eslint-disable prefer-const */
import axios from 'axios';
import { useState } from 'react';
import Lottie from 'react-lottie';
import LoadingBeer from '../../assets/animations/loadings/loading_beer.json';
import { Container, ThreeDots } from './styles';

/* const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingBeer,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}; */

type LoadingProps = {
  isOpen: boolean;
  isLink?: string;
};

export function Loading({ isOpen, isLink = '' }: LoadingProps): JSX.Element {
  const [lottie, setLottie] = useState({});
  if (Object.keys(lottie).length === 0 && isLink !== '') {
    axios
      .get(isLink)
      .then(({ data }) => {
        setLottie(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Object.keys(lottie).length > 0 ? lottie : LoadingBeer,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container isOpen={isOpen}>
      <div>
        <Lottie options={defaultOptions} width="20rem" isClickToPauseDisabled />
        <div>
          <span>Carregando</span>
          <ThreeDots />
        </div>
      </div>
    </Container>
  );
}
