import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

interface HeaderProps {
  caracteres: number;
}

export const Header = styled.header<HeaderProps>`
  grid-area: header;
  max-height: auto;
  border-radius: 5px 5px 0 0;

  align-items: center;
  padding: 1.5rem 1.25rem;
  overflow: scroll;
  display: flex;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @media screen and (min-width: 900px) and (max-width: 1420px) {
    padding: 1rem 0.75rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  section {
    align-self: flex-start;
    height: 15rem;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    img {
      max-width: 15rem;
      max-height: 15rem;
      display: block;
      margin: 0 auto;
      border-radius: 8%;
      @media screen and (min-width: 900px) and (max-width: 1420px) {
        max-width: 8rem;
        max-height: 8rem;
      }
    }
  }

  div {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1.25rem;

    strong {
      font-size: 2.5rem;
      line-height: 3.5rem;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      font-weight: 700;
      width: 100%;

      @media screen and (min-width: 900px) and (max-width: 1420px) {
        font-size: 2rem;
      }
    }

    span {
      display: flex;
      flex-basis: 2;
      margin-top: 0.5rem;
      font-size: 1.5rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
      padding-bottom: 1.5rem;
      width: 100%;
      @media screen and (min-width: 900px) and (max-width: 1420px) {
        font-size: 1rem;
      }
    }
  }
`;

export const HeaderNotQuestions = styled.header`
  grid-area: header;
  border-radius: 5px 5px 0 0;
  padding: 1.25rem;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 900px) and (max-width: 1420px) {
    padding: 0.5rem;
  }

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  strong {
    font-family: Poppins;
    font-size: 2.5rem;
    line-height: 3rem;
    color: ${({ theme }) => theme && theme.colors.DefaultColor};

    @media screen and (min-width: 900px) and (max-width: 1420px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  @media (max-width: 360px) {
    strong {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  @media (min-width: 500px) {
    strong {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  @media (min-width: 768px) {
    strong {
      font-size: 4.5rem;
      line-height: 5rem;
    }
    @media (orientation: landscape) {
      strong {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    }
  }

  section {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    width: 30%;
    @media screen and (min-width: 900px) and (max-width: 1420px) {
      margin: 0.5 0;
    }
    @media (orientation: landscape) {
      height: 50%;
    }
    height: 50%;
    img {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 8%;
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.6);
    }
  }

  @media (orientation: portrait) {
    section {
      width: 70%;
      aspect-ratio: 3 / 2;
    }
  }

  span {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.Background).isLight()
        ? darken(0.8, theme.colors.Background)
        : lighten(0.8, theme.colors.Background)};
    padding-bottom: 1.5rem;
  }

  @media (max-width: 360px) {
    span {
      font: 0.9rem Poppins;
    }
  }

  @media (min-width: 500px) {
    span {
      font: 1rem Poppins;
    }
  }

  @media (min-width: 768px) {
    span {
      font: 1.5rem Poppins;
    }
    @media (orientation: landscape) {
      span {
        font: 1.7rem Poppins;
      }
    }
  }
`;
