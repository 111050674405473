/* eslint-disable prettier/prettier */
import { isValidPhoneNumber } from 'libphonenumber-js';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { IDataDesconto } from '../pages/Menu/Modais/ModalDiscont';
// eslint-disable-next-line import/no-extraneous-dependencies

interface numberContextData {
  confirmMobileNumber: () => boolean;
  setMobileNumber: (e: string) => void;
  mobileNumber: string;
  setCode: (e: string) => void;
  code: string;
  setConfirmNumber: (e: boolean) => void;
  confirmNumber: boolean;
  setConfirmNumberSMS: (e: boolean) => void;
  confirmNumberSMS: boolean;
  addIdNumber: (e: string) => void;
  addIdNumberCode: (e: string) => void;
  addIdCpfCode: (e: string) => void;
  setCpf: (e: string) => void;
  setEmail: (e: string) => void;
  setCupom: Dispatch<SetStateAction<string>>;
  setDataDesconto: (e: IDataDesconto) => void;
  cpf: string;
  dataDesconto: IDataDesconto;
  email: string;
  cupom: string;
  messageErrorCpf: string;
  setMessageErrorCpf: (e: string) => void;
  confirmNFModal: () => boolean;
  confirmCPFModal: () => boolean;
}

interface NumberProviderProps {
  children: ReactNode;
}

export const NumberContext = createContext({} as numberContextData);

export function NumberProvider({ children }: NumberProviderProps): JSX.Element {
  const [confirmNumberSMS, setConfirmNumberSMS] = useState(false);
  const [confirmNumber, setConfirmNumber] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [code, setCode] = useState('');
  const [messageErrorCpf, setMessageErrorCpf] = useState('')
  const [cpf, setCpf] = useState('');
  const [dataDesconto, setDataDesconto] = useState<IDataDesconto>({} as IDataDesconto);
  const [email, setEmail] = useState('');
  const [cupom, setCupom] = useState('');

  function addIdNumber(value: string) {
    if (value === 'delete') {
      setMobileNumber((prev) => prev.slice(0, -1));
      return;
    }
    setMobileNumber((prev) => prev + value);
  }

  function addIdNumberCode(value: string) {
    if (value === 'delete') {
      setCode((prev) => prev.slice(0, -1));
      return;
    }
    setCode((prev) => prev + value);
  }

  function confirmMobileNumber() {
    const isValid = mobileNumber.length <= 15;
    const isValidTelefone = isValidPhoneNumber(mobileNumber, 'BR');
    // if (!(isValid && isValidTelefone))
    return isValid && isValidTelefone;
  }

  function addIdCpfCode(value: string) {
    if (value === 'delete') {
      setCpf((prev) => prev.slice(0, -1));
      return;
    }

    if(cpf.length >= 15){
      setMessageErrorCpf('o limite de digitos não pode ser ultrapassado.')
      return;
    }

    setCpf((prev) => prev + value);
    setMessageErrorCpf('')
  }

  function isFirstCpfDigitValid(value: string) {
    const verificatorDigit = Number(value.slice(9, 10));
    const firstDigits = value
      .slice(0, -2)
      .split('')
      .map((x) => + x);

    const result = firstDigits.reduce((a, b, i) => a + b * (10 - i), 0);
    let resto = result % 11;
    if (resto < 2) {
      resto = 0;
      return resto === verificatorDigit;
    }
    resto = 11 - resto;
    return resto === verificatorDigit;
  }

  function isSecondCpfDigitValid(value: string) {
    const verificatorDigit = Number(value.slice(10, 11));
    const firstDigits = value
      .slice(0, -1)
      .split('')
      .map((x) => +x);

    const result = firstDigits.reduce((a, b, i) => a + b * (11 - i), 0);
    let resto = result % 11;
    if (resto < 2) {
      resto = 0;
      return resto === verificatorDigit;
    }
    resto = 11 - resto;
    return resto === verificatorDigit;
  }

  function isFirstCnpjDigitValid(value: string): boolean {
    const verificatorDigit = +value[12];
    const firstDigits = value.slice(0, 12).split('').map(Number);

    const multipliers = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const result = firstDigits.reduce((sum, digit, i) => sum + digit * multipliers[i], 0);

    const resto = result % 11;
    const calculatedDigit = resto < 2 ? 0 : 11 - resto;

    return calculatedDigit === verificatorDigit;
  }

  function isSecondCnpjDigitValid(value: string): boolean {
    const verificatorDigit = +value[13];
    const firstDigits = value.slice(0, 13).split('').map(Number);

    const multipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const result = firstDigits.reduce((sum, digit, i) => sum + digit * multipliers[i], 0);

    const resto = result % 11;
    const calculatedDigit = resto < 2 ? 0 : 11 - resto;

    return calculatedDigit === verificatorDigit;
  }


  function confirmNFModal() {
    const isValidEmail = /\S+@\S+\.\S+/.test(email);

    if (!isValidEmail) {
      // toast.error('Ops!! Email inválido');
      return false;
    }
    return true;
  }

  function confirmCPFModal() {
    setMessageErrorCpf('');
    const value = cpf.replace(/\D/g, '');
    if (value.length === 0) {
      setMessageErrorCpf('O campo CPF não pode ficar vazio!');
      return false;
    }
    if (value.length <= 11 && (!(/^\d{3}(\.|\s)?\d{3}(\.|\s)?\d{3}(-\s?)?\d{2}$/.test(value)) || !isFirstCpfDigitValid(value) || !isSecondCpfDigitValid(value))) {
      setMessageErrorCpf('CPF inválido!');
      return false;
    }
    if (value.length > 11 ) {
      setMessageErrorCpf('CNPJ - Caso deseje utilizar seu CNPJ informe-o no caixa.');
      return false;
    }
    return true;
  }

  return (
    <NumberContext.Provider
      value={{
        confirmMobileNumber,
        mobileNumber,
        setMobileNumber,
        code,
        setCode,
        confirmNumber,
        setConfirmNumber,
        confirmNumberSMS,
        setConfirmNumberSMS,
        addIdNumber,
        addIdNumberCode,
        addIdCpfCode,
        setCpf,
        setEmail,
        setCupom,
        setDataDesconto,
        messageErrorCpf,
        setMessageErrorCpf,
        cpf,
        email,
        dataDesconto,
        cupom,
        confirmNFModal,
        confirmCPFModal,
      }}
    >
      {children}
    </NumberContext.Provider>
  );
}
