import { useContext } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { NumberFormat } from '../../../../../components/NumberFormat';
import {
  ModalContext,
  QuestionSubItem,
} from '../../../../../contexts/ModalContext';

import { Item } from './styles';

interface QuestionProps {
  questionItem: QuestionSubItem;
  hidePrice: boolean;
  questionId: number;
  itemId: number;
  totalCount: number;
  maxQuantity: number;
}

export function MultipleQuestion({
  questionItem,
  hidePrice,
  questionId,
  itemId,
  totalCount,
  maxQuantity,
}: QuestionProps) {
  const { handleRemoveMultipleItem, handleAddMultipleItem } =
    useContext(ModalContext);

  return (
    <Item>
      <div>
        <span>{questionItem.opcao}</span>
        {!hidePrice && (
          <NumberFormat prefix="(+ " suffix=")">
            {questionItem.preco}
          </NumberFormat>
        )}
      </div>
      <div>
        <button
          type="button"
          disabled={questionItem.count === 0}
          onClick={() =>
            handleRemoveMultipleItem(questionItem.count, questionId, itemId)
          } /* eslint react/jsx-curly-newline: 0 */
        >
          <FaMinus />
        </button>
        <input type="button" readOnly value={questionItem.count} />
        <button
          type="button"
          disabled={totalCount === maxQuantity}
          onClick={() => handleAddMultipleItem(questionId, itemId)}
        >
          <FaPlus />
        </button>
      </div>
    </Item>
  );
}
