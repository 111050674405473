import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

type MensagemProps = {
  isOpen: boolean;
};
export const Container = styled.div<MensagemProps>`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;
    max-width: 70%;

    padding: 1rem;

    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme && theme.colors.Background};
    align-items: center;
    justify-content: center;

    ${({ theme }) =>
      tinycolor(theme.colors.Background).isDark() &&
      css`
        border: 1px solid;
        border-color: ${theme.colors.SecondaryColor};
      `};
    svg {
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      font-size: 10rem;

      margin-top: 1rem;
    }
    strong {
      font-family: Poppins;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
    }
    h1 {
      font: 3.25rem Poppins;
      color: black;
      line-height: 4rem;
      text-align: center;
      margin-top: 3rem;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isDark()
          ? 'white'
          : 'black'};
    }

    div {
      width: 100%;

      display: flex;
      justify-content: space-around;
      padding: 1rem;
      margin-top: 3rem;

      button {
        flex: 1;

        background: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.15, theme.colors.Background)
            : lighten(0.15, theme.colors.Background)};
        color: #ffffff;
        border: 0;
        border-radius: 1rem;
        padding: 1.5rem;

        font-family: Poppins;
        font-size: 2rem;
        font-weight: 600;

        &:last-child {
          margin-left: 2rem;
          //color: ${({ theme }) => theme && theme.colors.SecondaryColor};
          background: ${({ theme }) =>
            theme && tinycolor(theme.colors.DefaultColor).isLight()
              ? darken(0.15, theme.colors.DefaultColor)
              : lighten(0.15, theme.colors.DefaultColor)};
        }
      }
    }
  }

  ${({ isOpen }) =>
    !isOpen
      ? css`
          display: none;

          & > div {
            opacity: 0;
          }
        `
      : css`
          display: flex;

          & > div {
            opacity: 1;
          }
        `}
`;
