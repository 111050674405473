/* eslint-disable no-unused-expressions */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import api from '../services/api';
import { CompanyContext } from './CompanyContext';
import { ProductContext } from './ProductContext';

interface ModalContextData {
  isModalVisible: boolean;
  isModalCreditVisible: boolean;
  questions: Question[];
  changeItem: (e: QuestionSubItem, index: number) => void;
  handleRemoveMultipleItem: (
    actualCount: number,
    questionId: number,
    index: number,
  ) => void;
  handleAddMultipleItem: (questionId: number, index: number) => void;
  finalCount: number;
  handleDecrementFinalCount: () => void;
  handleIncrementFinalCount: () => void;
  finalPrice: number;
  disableCheckout: boolean;
  addToCart: () => void;
  tryAddToCart: boolean;
  tryUpdateCart: boolean;
  closeModal: () => void;
  closeCreditModal: () => void;
  addIdNumber: (e: string) => void;
  confirmCreditModal: (e?:string) => void;
  documentNumber: string;
  selectOrderItem: (item: OrderItem) => void;
  selectedItem: OrderItem;
  setDocumentInvalidMessage: Dispatch<SetStateAction<string>>;
  documentInvalidMessage: string;
  observation: string;
  setObservation: Dispatch<SetStateAction<string>>;
  addCpfNumber: (e: string) => void;
  addEmail: (e: string) => void;
  cpf: string;
  email: string;
  isModalNFVisible: boolean;
  setIsModalNfVisible: Dispatch<SetStateAction<boolean>>;
  rfid: string;
  openCartCredit: boolean;
  setOpenCartCredit: Dispatch<SetStateAction<boolean>>;
}

interface ModalProviderProps {
  children: ReactNode;
}

interface Question {
  ordem: number;
  exibir: boolean;
  compoePrecoProduto: boolean;
  subtotal: number;
  count: number;
  selectedItem: number;
  pergunta: QuestionItem;
}

interface QuestionItem {
  id: number;
  nome: string;
  minimo: number;
  maximo: number;
  repeteOpcao: boolean;
  ocultaPreco: boolean;
  perguntaItens: QuestionSubItem[];
}

export interface QuestionSubItem {
  id: number;
  opcao: string;
  preco: number;
  ordem: number;
  exibir: boolean;
  count: number;
  subtotal: number;
}

interface OrderItem {
  id: number;
  isCredit: boolean;
  isObservation: boolean;
  productId: number;
  tagRfid: string;
  document: string;
  observation: string;
  image: string;
  name: string;
  description: string;
  quantity: number;
  unitPrice: number;
  oldUnitPrice: number;
  total: number;
  ativo: boolean;
  grupoId: number;
  subGrupoId: number;
  subItems: OrderSubItem[];
}

interface OrderSubItem {
  id: number;
  questionId: number;
  selectedSubItemId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  oldUnitPrice: number;
  isSingleOption: boolean;
}

export const ModalContext = createContext({} as ModalContextData);

export function ModalProvider({ children }: ModalProviderProps): JSX.Element {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [finalCount, setFinalCount] = useState(1);
  const [finalPrice, setFinalPrice] = useState(0);
  const [disableCheckout, setDisableCheckout] = useState(true);
  const [tryAddToCart, setTryAddToCart] = useState(false);
  const [tryUpdateCart, setTryUpdateCart] = useState(false);
  const [isModalCreditVisible, setIsModalCreditVisible] = useState(false);
  const [isModalNFVisible, setIsModalNfVisible] = useState(false);
  const [documentNumber, setDocumentNumber] = useState('');
  const [observation, setObservation] = useState('');
  const [selectedItem, setSelectedItem] = useState({} as OrderItem);
  const [documentInvalidMessage, setDocumentInvalidMessage] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [rfid, setRfid] = useState('');
  const [openCartCredit, setOpenCartCredit] = useState(false);

  const { selectedProduct, selectProduct } = useContext(ProductContext);
  const {
    selectedCompany,
    isOrderFinalized,
    setIsLoading,
    setIsMsgBox,
    setOpenIsMsgBox,
  } = useContext(CompanyContext);

  useEffect(() => {
    setOpenCartCredit(false);
    setTryAddToCart(false);
    setTryUpdateCart(false);
    setIsModalVisible(false);
    setIsModalCreditVisible(false);
    setFinalPrice(0);
    setFinalCount(1);
    setQuestions([]);
    setSelectedItem({} as OrderItem);
    setDocumentNumber('');
    setRfid('');
  }, [isOrderFinalized]);

  function updatePrice(items: Question[]) {
    if (items.length === 0) return;

    const updatedQuestions = items.map((item) => {
      const question = item;

      question.count = question.pergunta.perguntaItens
        .map((a) => a.count)
        .reduce((a, b) => {
          return a + b;
        });

      question.subtotal = question.pergunta.perguntaItens
        .map((a) => a.subtotal)
        .reduce((a, b) => {
          return a + b;
        });

      return question;
    });

    const sumPrice: number = updatedQuestions
      .map((a) => a.subtotal)
      .reduce((a, b) => {
        return a + b;
      });

    setFinalPrice(sumPrice);
    setQuestions(updatedQuestions);
  }

  useEffect(() => {
    async function loadQuestions() {
      setIsLoading(true);
      try {
        const response = await api.get(
          `api/v1/ProdutoPergunta/GetListProdutoPerguntaV2/${selectedCompany.id}/
          ${Object.keys(selectedProduct).length !== 0
            ? selectedProduct.idProduto
            : selectedItem.productId
          }`,
        );
        const resp = response.data.filter((x: Question)=> x.exibir === true).sort((a:Question, b:Question) => a.ordem - b.ordem);
        if (resp.length > 0) {
          if (Object.keys(selectedProduct).length !== 0) {
            const updatedQuestions = resp.map((res: Question) => {
              const question = res
              const items = res.pergunta.perguntaItens.filter((x: QuestionSubItem)=> x.exibir === true).sort((a:QuestionSubItem, b:QuestionSubItem) => a.ordem - b.ordem).map(
                (item: QuestionSubItem) => {
                  const newItem = item;
                  newItem.count = 0;
                  newItem.subtotal = 0;
                  return newItem;
                },
              );
              question.count = 0;
              question.subtotal = 0;
              question.selectedItem = 0;
              question.pergunta.perguntaItens = items;
              if(res.pergunta.maximo === 1 && res.pergunta.minimo === 1) {
                if(res.pergunta.perguntaItens.length === 1) {
                  question.pergunta.perguntaItens[0].count = 1;
                  question.pergunta.perguntaItens[0].subtotal = question.pergunta.perguntaItens[0].preco;
                  question.count = 1;
                  question.subtotal = question.pergunta.perguntaItens[0].preco;
                  question.selectedItem = question.pergunta.perguntaItens[0].id;
                }
              }
              return question;
            });

            setQuestions(updatedQuestions);
          } else {
            if (selectedItem.isCredit) {
              setDocumentNumber(selectedItem.document);
              setRfid(selectedItem.tagRfid);
            }
            const editedQuestions = resp.map((res: Question) => {
              const question = res;

              const items = question.pergunta.perguntaItens.filter((x: QuestionSubItem)=> x.exibir === true).map(
                (item: QuestionSubItem) => {
                  const newItem = item;
                  newItem.count = 0;
                  newItem.subtotal = 0;

                  const filteredSubitems = selectedItem.subItems.filter(
                    (subitem) => subitem.selectedSubItemId === item.id,
                  );

                  if (filteredSubitems.length === 0) return newItem;

                  filteredSubitems.forEach((obj) => {
                    if (obj.selectedSubItemId === item.id) {
                      newItem.count = obj.quantity / selectedItem.quantity;
                      newItem.subtotal =
                        (obj.unitPrice * obj.quantity) / selectedItem.quantity;
                        (obj.oldUnitPrice * obj.quantity) / selectedItem.quantity;
                    }

                    if (obj.isSingleOption) {
                      question.selectedItem = obj.selectedSubItemId;
                    }
                  });

                  return newItem;
                },
              );

              question.pergunta.perguntaItens = items;

              const itemCount = selectedItem.subItems
                .filter((item) => {
                  return question.pergunta.id === item.questionId;
                })
                .map((item) => {
                  return item.quantity;
                });

              if (itemCount.length === 0) return question;

              question.count = itemCount.reduce((a, b) => a + b);

              return question;
            });

            updatePrice(editedQuestions);
          }
        }

        setFinalCount(
          Object.keys(selectedItem).length !== 0 ? selectedItem.quantity : 1,
        );
        setIsModalVisible(true);

      } catch (error) {
        console.log(error);
      }finally{
        setIsLoading(false);
      }

    }

    if (
      Object.keys(selectedProduct).length === 0 &&
      Object.keys(selectedItem).length === 0
    )
      return;
    loadQuestions();
  }, [
    selectedProduct,
    selectedCompany,
    selectedItem,
    setIsLoading,
  ]);

  useEffect(() => {
    const isDisabled = questions.every((q) => {
      if (q.pergunta.minimo >= 1) {
        return q.count >= q.pergunta.minimo;
      }
      return true;
    });

    setDisableCheckout(!isDisabled);
  }, [questions]);

  function selectOrderItem(item: OrderItem) {
    setSelectedItem(item);
  }

  function changeItem(questionSubItem: QuestionSubItem, questionId: number) {
    const updatedQuestions = questions.map((prop) => {
      if (prop.pergunta.id === questionId) {
        const item = prop;

        const subItems = prop.pergunta.perguntaItens.map((subItem) => {
          const newSubItem = subItem;

          if (subItem.id === questionSubItem.id) {
            newSubItem.count = 1;
            newSubItem.subtotal = newSubItem.preco;
          } else {
            newSubItem.count = 0;
            newSubItem.subtotal = 0;
          }

          return newSubItem;
        });

        item.selectedItem = questionSubItem.id;
        item.pergunta.perguntaItens = subItems;

        return item;
      }
      return prop;
    });

    updatePrice(updatedQuestions);
  }

  function handleRemoveMultipleItem(
    actualCount: number,
    questionId: number,
    itemId: number,
  ) {
    if (actualCount === 0) return;

    const updatedQuestions = questions.map((item) => {
      const question = item;

      if (question.pergunta.id === questionId) {
        question.pergunta.perguntaItens = question.pergunta.perguntaItens.map(
          (subItem) => {
            if (subItem.id === itemId) {
              const newSubItem = subItem;
              newSubItem.count -= 1;
              newSubItem.subtotal = newSubItem.count * newSubItem.preco;
              return newSubItem;
            }

            return subItem;
          },
        );
      }

      return question;
    });

    updatePrice(updatedQuestions);
  }

  function handleAddMultipleItem(questionId: number, itemId: number) {
    const updatedQuestions = questions.map((item) => {
      const question = item;

      if (question.pergunta.id === questionId) {
        question.pergunta.perguntaItens = question.pergunta.perguntaItens.map(
          (subItem) => {
            if (subItem.id === itemId) {
              const newSubItem = subItem;
              newSubItem.count += 1;
              newSubItem.subtotal = newSubItem.count * newSubItem.preco;
              return newSubItem;
            }

            return subItem;
          },
        );
      }

      return question;
    });

    updatePrice(updatedQuestions);
  }

  function handleDecrementFinalCount() {
    setFinalCount((prev) => prev - 1);
  }

  function handleIncrementFinalCount() {
    setFinalCount((prev) => prev + 1);
  }

  function addToCart() {
    if (selectedProduct.geraValorEmCredito || selectedItem.isCredit) {
      setIsModalCreditVisible(true);
      return;
    }

    if (Object.keys(selectedProduct).length === 0) {
      setTryUpdateCart(true);
    } else {
      setTryAddToCart(true);
    }
  }

  function closeModal() {
    setTryAddToCart(false);
    setTryUpdateCart(false);
    setIsModalVisible(false);
    selectProduct(0);
    setFinalPrice(0);
    setFinalCount(1);
    setQuestions([]);
    setSelectedItem({} as OrderItem);
    setDocumentNumber('');
    setObservation('');
  }

  function closeCreditModal() {
    setDocumentNumber('');
    setRfid('');
    setIsModalCreditVisible(false);
  }

  function isFirstDigitValid(value: string) {
    const verificatorDigit = Number(value.slice(9, 10));
    const firstDigits = value
      .slice(0, -2)
      .split('')
      .map((x) => +x);

    const result = firstDigits.reduce((a, b, i) => a + b * (10 - i), 0);
    let resto = result % 11;
    if (resto < 2) {
      resto = 0;
      return resto === verificatorDigit;
    }
    resto = 11 - resto;
    return resto === verificatorDigit;
  }

  function isSecondDigitValid(value: string) {
    const verificatorDigit = Number(value.slice(10, 11));
    const firstDigits = value
      .slice(0, -1)
      .split('')
      .map((x) => +x);

    const result = firstDigits.reduce((a, b, i) => a + b * (11 - i), 0);
    let resto = result % 11;
    if (resto < 2) {
      resto = 0;
      return resto === verificatorDigit;
    }
    resto = 11 - resto;
    return resto === verificatorDigit;
  }

  async function isCheckDocumentMyTapp(document: string, rfidNumber: string) {
    const consulta = {
      document,
      rfid:rfidNumber,
      chaveSeguranca: btoa(
        `${selectedCompany.id}@IDX@SISTEMAS_${selectedCompany.id}`,
      ),
    };

    const response = await api.post(
      `/api/v1/MyTapp/CheckStatusClient/${selectedCompany.id}`,
      consulta,
      {
        withCredentials: true,
      },
    );
    if (!response.data.success) {
      setDocumentInvalidMessage("CPF não localizado na MyTapp, verifique se o CPF digitado está correto e se possui cadastro na MyTapp!");
      return false;
    }
    return true;
  }

  async function confirmCreditModal(rfidNumber= '') {
    if(documentNumber === '' && rfidNumber === '') return;
    if(documentNumber !== '' && rfidNumber === ''){
      const isCorrect = /([0-9]){3}[\.\-]?([0-9]){3}[\.\-]?([0-9]){3}[\-]?([0-9]){2}$/.test(
        documentNumber,
      );

      if (
        !isCorrect ||
        !isFirstDigitValid(documentNumber) ||
        !isSecondDigitValid(documentNumber)
      ) {
        setDocumentInvalidMessage("CPF inválido!");
        // alert('CPF inválido!');
        return;
      }
    }
    // validando se cpf digitado existe na mytapp
    const mytappOK = await isCheckDocumentMyTapp(documentNumber, rfidNumber);
    if (!mytappOK) return;

    setIsModalCreditVisible(false);
    if(rfidNumber){
      setIsMsgBox('Recarga adicionado no carrinho. Por favor, realize o pagamento!');
      setOpenIsMsgBox(true);
      setRfid(rfidNumber);
    }
    setOpenCartCredit(true);
    if (Object.keys(selectedProduct).length === 0)
      setTryUpdateCart(true);
    else
      setTryAddToCart(true);
  }

  function addIdNumber(value: string) {
    if (value === 'delete') {
      setDocumentNumber((prev) => prev.slice(0, -1));
      return;
    }

    if(documentNumber.length >= 11) return;
    setDocumentNumber((prev) => prev + value);
  }

  function addCpfNumber(value: string) {
    if (value === 'delete') {
      setCpf((prev) => prev.slice(0, -1));
      return;
    }
    setCpf((prev) => prev + value);
  }

  function addEmail(value: string) {
    if (value === 'delete') {
      setEmail((prev) => prev.slice(0, -1));
      return;
    }
    setEmail((prev) => prev + value);
  }

  return (
    <ModalContext.Provider
      value={{
        isModalVisible,
        isModalCreditVisible,
        questions,
        changeItem,
        handleRemoveMultipleItem,
        handleAddMultipleItem,
        finalCount,
        handleDecrementFinalCount,
        handleIncrementFinalCount,
        finalPrice,
        disableCheckout,
        addToCart,
        tryAddToCart,
        tryUpdateCart,
        closeModal,
        closeCreditModal,
        addIdNumber,
        confirmCreditModal,
        documentNumber,
        selectOrderItem,
        selectedItem,
        documentInvalidMessage,
        setDocumentInvalidMessage,
        observation,
        setObservation,
        addCpfNumber,
        addEmail,
        cpf,
        email,
        isModalNFVisible,
        setIsModalNfVisible,
        rfid,
        openCartCredit,
        setOpenCartCredit,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
