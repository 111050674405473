import { useCallback, useEffect, useRef } from 'react';

import {
  Popover as TinyPopover,
  PopoverProps as TinyPopoverProps,
} from 'react-tiny-popover';

type Props = Omit<TinyPopoverProps, 'isOpen'> & {
  isOpen: boolean;
  onClose: () => void;
};

interface PopoverProps extends TinyPopoverProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Popover({
  content,
  children,
  onClickOutside,
  isOpen,
  onClose,
  ...props
}: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const handleOnClickOutside = useCallback(
    (e: MouseEvent) => {
      const menu = ref?.current;
      const list = listRef?.current;

      if (
        !isOpen ||
        menu?.contains(e?.target as Node) ||
        list?.contains(e?.target as Node)
      ) {
        return;
      }

      onClose();
      onClickOutside?.(e);
    },
    [isOpen, onClose, onClickOutside, ref, listRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutside);
    };
  }, [handleOnClickOutside]);

  return (
    <TinyPopover
      isOpen={isOpen}
      align="start"
      positions={['bottom', 'top']}
      content={isOpen ? content : <span />}
      ref={ref}
      {...props}
    >
      {children}
    </TinyPopover>
  );
}
