import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled, { css } from 'styled-components';

interface ImgTotem {
  Load: boolean;
  logope: boolean;
  logopd: boolean;
  logoPor: number;
}

export const Banner = styled.div<ImgTotem>`
  position: relative;

  button {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
    border: 0;

    ${({ logopd, logope, logoPor }) =>
      // eslint-disable-next-line no-nested-ternary
      logopd
        ? css`
            & > img {
              position: absolute;
              margin-left: auto;
              margin-right: auto;
              max-width: 16%;
              bottom: 3.5%;
              left: 4%;
              text-align: center;
              width: 18rem;
              margin-top: -12.5rem;
              z-index: 2;

              @media screen and (orientation: landscape) {
                width: 7rem;
              }
            }
          `
        : logope
        ? css`
            & > img {
              position: absolute;
              margin-left: auto;
              margin-right: auto;
              max-width: ${10.5 - logoPor}%;
              bottom: ${3.5 - logoPor * 0.5495}%;
              left: 4%;
              text-align: center;
              width: 8rem;
              margin-top: -12.5rem;
              z-index: 2;
            }
          `
        : css`
            & > img {
              position: absolute;
              margin-left: auto;
              margin-right: auto;
              max-width: 12.5%;
              bottom: 3%;
              left: 4%;
              text-align: center;
              width: 8rem;
              margin-top: -12.5rem;
              z-index: 2;
            }
          `}

    #banner_padrao {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #151414;
    }

    div:last-child {
      position: relative;
      display: flex;
      justify-content: center;
      background: #151414;
      height: 20%;
      strong {
        margin-top: 0.8rem;
        text-align: center;
        color: #fff;
        font-weight: 900;
      }

      img {
        position: absolute;
        right: 0;
        align-self: flex-end;
        margin: 0 1.5rem 2.3rem;
        max-width: 12rem;
        top: 50%;
        margin-top: -1.6rem;

        @media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
          top: 25%;
        }
      }

      h1 {
        position: absolute;
        right: 0;
        align-self: flex-end;
        margin: 0 3rem 2.3rem;
        max-width: 9rem;
        color: #fff;
        font: 1.2rem Heinberg, sans-serif;
        top: 75%;
      }
    }
  }

  .imgSlider {
    border: 0;
    margin: 0;
    height: 80%;
    width: 100%;
    display: block;
    img {
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (max-device-width: 1080px) {
    strong {
      font: 5rem Heinberg, sans-serif;
    }
  }
  @media only screen and (max-device-width: 768px) {
    strong {
      font: 4.5rem Heinberg, sans-serif;
    }
  }
  @media (orientation: landscape) {
    strong {
      font: 3.5rem Heinberg, sans-serif;
    }
    #comercial_connx {
      top: 0;
      margin-top: 0;
    }
  }
  @keyframes changeBackgroundColor {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 50;
    }
    40% {
      opacity: 100;
    }
  }
`;

export const Carrocel = styled(Slider)`
  .slick-slider .slick-track .slick-slider .slick-list {
    display: flex !important;
  }
  .slick-slider {
    height: 100%;
    padding: 0 !important;
  }

  .slick-list {
    width: 100vw !important;
    padding: 0 !important;
  }

  .slick-list .slick-track {
    height: 80vh !important;
    padding: 0 !important;
  }

  .slick-slide {
    opacity: 1;
    width: 100vw !important;
    height: 80vh !important;
    div {
      width: 100vw !important;
      height: 80vh !important;
      @media (orientation: landscape) {
        margin: 0 30%;
        width: 40vw !important;
      }
      .banners {
        width: 100%;
        height: 100%;
        position: relative !important;
        display: flex !important;
        top: 0;
        max-width: 100%;
      }
    }
  }

  .slick-dots {
    display: flex;
    position: absolute;
    z-index: 999;
    top: 75vh;
    height: 2rem;
    width: auto;
    border-radius: 0.5rem;

    li {
      width: 2rem;
      height: 2rem;
    }

    button::before {
      width: 2rem;
      height: 2rem;
      font-size: 2.5rem;
      color: #f2f2f2;
    }

    li.slick-active button:before {
      color: #f2f2f2;
    }
  }
`;

interface AppCardProps {
  Cervejaria?: boolean;
}

export const TouchButton = styled.button``;

export const AppCard = styled.div<AppCardProps>`
  ${({ Cervejaria }) =>
    Cervejaria
      ? css`
          position: absolute;
          max-width: 43%;
          max-height: 12%;
          padding: 1rem;
          z-index: 1;
          bottom: 1%;
          left: 30%;
          border-radius: 0.5rem;
          background: #fff;
          h1 {
            font-size: 1.5rem;
            @media (max-width: 768px) {
              font-size: 1.2rem;
            }
            font-family: Heinberg;
            color: #242424;
            text-align: center;
          }
          main {
            width: 80%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            align-content: center;
            margin: 0 auto;
            section {
              img {
                width: 50%;
                height: 50%;
                //object-fit: contain;
              }
            }
          }
        `
      : css`
          display: none;
        `}
`;
