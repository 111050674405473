import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 10;
  background-color: ${({ theme }) =>
    theme && tinycolor(theme.colors.Background).isDark()
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(0, 0, 0, 0.8)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;
    max-width: 70%;

    padding: 1rem;

    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme && theme.colors.Background};
    align-items: center;
    justify-content: center;
    font-family: Poppins;

    svg {
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
      font-size: 10rem;

      margin-top: 1rem;
    }

    h1 {
      font-size: 2.8rem;
      line-height: 4rem;
      text-align: center;

      margin-top: 3rem;

      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.9, theme.colors.Background)
          : lighten(0.9, theme.colors.Background)};
    }

    #mensagem_error {
      font-size: 3.2rem;
      color: ${({ theme }) => theme && theme.colors.DefaultColor};
    }

    div {
      width: 100%;

      display: flex;
      justify-content: space-around;
      padding: 1rem;
      margin-top: 3rem;

      button {
        flex: 1;

        border: 0;
        border-radius: 1rem;
        padding: 1.5rem;

        font-size: 3rem;
        font-weight: 600;
        background: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.15, theme.colors.Background)
            : lighten(0.15, theme.colors.Background)};
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.9, theme.colors.Background)
            : lighten(0.9, theme.colors.Background)};

        &:first-child {
          background: ${({ theme }) => theme && theme.colors.Background};
          margin-right: 1.25rem;
          color: ${({ theme }) => theme && theme.colors.DefaultColor};
          border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
        }

        &:last-child {
          margin-left: 2rem;
          background: ${({ theme }) => theme && theme.colors.DefaultColor};
          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.DefaultColor).isLight()
              ? darken(0.9, '#ffffff')
              : lighten(0.9, '#ffffff')};
          border: 0;
        }
      }
    }

    ${({ theme }) =>
      tinycolor(theme.colors.Background).isDark() &&
      css`
        border: 1px solid;
        border-color: ${theme.colors.SecondaryColor};
      `};
  }
`;
