/* eslint-disable no-nested-ternary */
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  // eslint-disable-next-line prettier/prettier
  useState
} from 'react';
import api from '../services/api';
import { CompanyContext } from './CompanyContext';

interface MenuListContextData {
  selectGroup: (id: number) => void;
  selectedGroup: Group;
  groups: Group[];
}

interface MenuListProviderProps {
  children: ReactNode;
}

interface Group {
  idGrupo: number;
  nome: string;
  imagem: string;
  banner: string;
  possuiSubGrupo: boolean;
  ordem: number;
}

interface Banner {
  path: string;
  title: string;
}

export const MenuListContext = createContext({} as MenuListContextData);

export function MenuListProvider({ children }: MenuListProviderProps) {
  const [selectedGroup, setSelectedGroup] = useState({} as Group);
  const [groups, setGroups] = useState<Group[]>([]);

  const {
    selectedCompany,
    isOrderFinalized,
    setIsLoading,
    baseUrl,
    serviceElectron,
  } = useContext(CompanyContext);

  useEffect(() => {
    async function loadGroups() {
      try {
        setIsLoading(true);
        const response = await api.get(
          `api/v1/CardapioGrupo/GetMenuDoCardapio/${selectedCompany.id}`,
        );

        const responseMenu = response.data;
        // eslint-disable-next-line no-restricted-syntax
        for (const grupo of responseMenu) {
          const index = responseMenu.findIndex(
            (x: any) => x.idGrupo === grupo.idGrupo,
          );
          if (grupo.imagem !== null && grupo.imagem !== '') {
            let logo = `${baseUrl}${grupo.imagem}`;
            if (serviceElectron?.isElectronApp) {
              // eslint-disable-next-line no-await-in-loop
              const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
                'getImage',
                grupo.imagem,
                false,
                '',
              );
              if (responseSmart.Sucesso) {
                logo = responseSmart.Msg;
              }
            }
            responseMenu[index].imagem = logo;
          }

          if (grupo.banner !== null && grupo.banner !== '') {
            let banner = `${baseUrl}${grupo.banner}`;
            if (serviceElectron?.isElectronApp) {
              // eslint-disable-next-line no-await-in-loop
              const responseSmart = await serviceElectron?.ipcRenderer?.invoke(
                'getImage',
                grupo.banner,
                false,
                '',
              );
              if (responseSmart.Sucesso) {
                banner = responseSmart.Msg;
              }
            }
            responseMenu[index].banner = banner;
          }
        }

        const group = responseMenu.reduce((res: Group, obj: Group) => {
          return obj.ordem < res.ordem ? obj : res;
        });
        setSelectedGroup(group);
        setGroups(responseMenu);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (Object.keys(selectedCompany).length === 0) return;

    loadGroups();
  }, [selectedCompany, setIsLoading]);

  useEffect(() => {
    if (!isOrderFinalized) return;

    setGroups([]);
    setSelectedGroup({} as Group);
  }, [isOrderFinalized]);

  function selectGroup(id: number) {
    const selected = groups.find((group) => group.idGrupo === id);
    setSelectedGroup(selected || ({} as Group));
  }

  return (
    <MenuListContext.Provider
      value={{
        selectGroup,
        selectedGroup,
        groups,
      }}
    >
      {children}
    </MenuListContext.Provider>
  );
}
