import styled from 'styled-components';

interface CompanyItemProps {
  isActive: boolean;
}

export const Item = styled.button<CompanyItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 25rem;
  height: 25rem;
  background: #fff;
  grid-column: span 2;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 1px 20px -15px #242424;
  position: relative;

  &:disabled {
    filter: brightness(0.8);
  }

  section {
    height: 11rem;
    width: 11rem;
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    img {
      display: block;
      margin: 0 auto;
      height: 100%;
      width: auto;
    }
  }

  strong {
    margin-top: 1rem;
    font-size: 2.5rem;
    color: #ff9e1b;
    font-family: Poppins;
  }
  span {
    background: ${(props) => (props.isActive ? '#2dc480' : '#e6674e')};
    padding: 0.5rem;
    font-family: Poppins;
    color: #fff;
    //font-size: 1.25rem;
    border-radius: 0.25rem;
    position: absolute;
    left: 5%;
    top: 5%;
  }

  div {
    background: #ff9e1b;
    border: 0;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    font-size: 2.75rem;
    color: #242424;
  }

  @keyframes slideUpfadeInFirst {
    0% {
      opacity: 0;
      margin-top: 4rem;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }

  @keyframes slideUpfadeInLasts {
    0% {
      opacity: 0;
      margin-top: 10rem;
    }
    100% {
      opacity: 1;
      margin-top: 4rem;
    }
  }
`;

export const Content = styled.div`
  background: #fff;
  padding: 1rem;
  font-size: 2rem;
  color: #242424;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 1px 20px -15px #242424;
`;
