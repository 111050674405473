import { useContext } from 'react';
import EmptyImage from '../../../../../assets/empty-image.png';
import { NumberFormat } from '../../../../../components/NumberFormat';
import { CheckoutContext } from '../../../../../contexts/CheckoutContext';
import { Container } from './styles';

export function Main(): JSX.Element {
  const { orderItems } = useContext(CheckoutContext);

  return (
    <Container>
      <ul>
        {orderItems.map((item) => (
          <li key={item.id}>
            <div>
              <strong>{item.name}</strong>
              <ul>
                {item.subItems.map((subitem) => (
                  <li key={subitem.id}>
                    <span>{subitem.quantity / item.quantity}</span>
                    <span>{subitem.name}</span>
                  </li>
                ))}
              </ul>
              <NumberFormat prefix="R$">{item.total}</NumberFormat>
            </div>
            <aside>
              <img
                src={item.image !== '' ? item.image : EmptyImage}
                alt="Logotipo da empresa"
              />
              <div>{item.quantity}</div>
            </aside>
          </li>
        ))}
      </ul>
    </Container>
  );
}
