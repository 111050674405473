/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-undef */
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { MdOutlineSensorsOff } from 'react-icons/md';
import logoTouch from '../../assets/logoTouchPreto.png';
import { Company } from '../../contexts/CompanyContext';
import {
  Body,
  FloatMessage,
  Footer,
  ModalContainer,
  ModalContent,
} from './styled';

export default function ModalConnection(): JSX.Element {
  const [company, setCompany] = useState<Company>({} as Company);
  useEffect(() => {
    const companySave = localStorage.getItem('@Totem:company');
    if (companySave) setCompany(JSON.parse(companySave));
    return () => {
      setCompany({} as Company);
    };
  }, []);

  useEffect(() => {
    if (!company) return;
    QRCode.toDataURL(`https://${company?.urlPWA}.connx.com.br`, { width: 900 })
      .then((url) => {
        const img = document.getElementById('qrcode') as HTMLImageElement;
        img.src = url;
      })
      .catch((err) => {
        console.error(err);
      });
  }, [company]);

  return (
    <ModalContainer>
      <ModalContent>
        <FloatMessage>
          <HiOutlineInformationCircle size={30} />
          <div>
            <h1>Falha na conexão</h1>
            <span>Dispositivo sem acesso à internet</span>
          </div>
          <MdOutlineSensorsOff size={30} />
        </FloatMessage>
        <Body>
          <h1>Acesse nosso cardápio pelo QR Code abaixo</h1>
          <div>
            <img id="qrcode" alt="QR Code" />
          </div>
        </Body>
        <Footer>
          <img src={logoTouch} />
          <img src={company.logotipo} />
        </Footer>
      </ModalContent>
    </ModalContainer>
  );
}
