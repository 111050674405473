/* eslint-disable no-alert */
import { FormHandles, SubmitHandler } from '@unform/core';
import { useContext, useRef } from 'react';
import { CompanyContext } from '../../../contexts/CompanyContext';
import api from '../../../services/api';
import { Input } from '../../Input';
import { Form as Unform } from './styles';

interface FormData {
  userName: string;
  password: string;
}

export function Form() {
  const formRef = useRef<FormHandles>(null);

  const {
    loadCompanies,
    setIsModalLoginVisible,
    setIsOrderFinalized,
    setRequestNumberMobile,
    setLinkLoading,
    setTotemNeedPayment,
    setIsAdminEmpresa,
    setTotemID,
  } = useContext(CompanyContext);

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    api
      .post('api/v1/users/login', data)
      .then((response) => {
        const {
          expiration,
          token,
          solicitaTelefone,
          user: {
            solicitaPagto,
            userName,
            id,
            painelSenha,
            totem,
            adminEmpresa,
          },
          totem: { totemMobile, loadingLink },
        } = response.data;
        data.password = btoa(data.password);
        if (!painelSenha && !totemMobile && totem) {
          localStorage.setItem('dataExpire', expiration);
          localStorage.setItem('user', userName);
          localStorage.setItem('totemLogin', JSON.stringify(data));
          localStorage.setItem('token', token);
          localStorage.setItem('solicitaPagto', solicitaPagto);
          setLinkLoading(loadingLink);
          setTotemID(id);
          setIsAdminEmpresa(adminEmpresa);
          setRequestNumberMobile(solicitaTelefone);
          setTotemNeedPayment(solicitaPagto);
          setIsModalLoginVisible(false);
          setIsOrderFinalized(false);
          loadCompanies();
        } else if (totemMobile || !totem)
          alert('Este usuário não tem permissão para acessar o terminal!');
        else alert('Este usuário não tem permissão para acessar o terminal!');
      })
      .catch(() =>
        alert(
          'Erro na autenticação do terminal! Verifique usuário/senha ou entre em contato com o administrador.',
        ),
      );
  };

  return (
    <Unform ref={formRef} onSubmit={handleSubmit}>
      <h1>Por favor, realizar login para prosseguir:</h1>
      <Input name="userName" placeholder="Insira seu usuário" />
      <Input name="password" placeholder="Insira sua senha" type="password" />
      <button type="submit">ENTRAR</button>
    </Unform>
  );
}
