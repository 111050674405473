import styled from 'styled-components';

interface MenuContentProps {
  banner: string | null;
  readOnlyMenu: boolean;
  titleLong: boolean;
}

export const Container = styled.div<MenuContentProps>`
  max-height: 100%;
  grid-area: mc;
  overflow: scroll;
  background-color: ${({ theme }) => theme && theme.colors.Background};
  margin-bottom: 7rem;
  //padding-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;
