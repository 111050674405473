import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.header`
  padding: 0 2rem;
  margin: 2rem 0;
  @media screen and (min-width: 900px) and (max-width: 1366px) {
    padding: 0 1rem;
    margin: 1rem 0;
  }
  & > h1 {
    font-family: Poppins;
    font-size: 2.5rem;

    text-align: center;
    color: ${({ theme }) => theme && theme.colors.DefaultColor};
    @media screen and (min-width: 900px) and (max-width: 1366px) {
      font-size: 1.5rem;
    }
  }

  div {
    font-family: Poppins;
    margin-top: 1rem;
    display: flex;
    align-items: center;

    @media screen and (min-width: 900px) and (max-width: 1366px) {
      margin-top: 0.5rem;
    }

    section {
      height: 7rem;
      width: 7rem;
      display: flex;
      align-items: center;
      border: 1px solid #ececec;
      border-radius: 50%;
      @media screen and (min-width: 900px) and (max-width: 1366px) {
        height: 4rem;
        width: 4rem;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
        border-radius: 50%;
      }
    }

    & > h1 {
      font: 2rem Poppins;
      line-height: 4rem;
      font-weight: bold;
      @media screen and (min-width: 900px) and (max-width: 1366px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      margin-left: 1rem;
      flex: 1;
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};
    }
  }

  div:last-child {
    border-bottom: 1px solid
      ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.3, theme.colors.Background)
          : lighten(0.5, theme.colors.Background)};
    padding: 1rem 0;
  }
`;
