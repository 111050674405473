/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { CheckoutContext, LastOrder } from '../../contexts/CheckoutContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { Container, Dot, ThreeDotsContainer } from './styles';

import LoadingBeer from '../../assets/animations/loadings/loading_beer.json';
import { ModalPayment } from '../../components/ModalPayment';

export function MensageTefDestaxa(): JSX.Element {
  const history = useHistory();
  const { setNewLastOrder, lastOrder, connection } =
    useContext(CheckoutContext);
  const { selectedCompany, linkLoading: isLink } = useContext(CompanyContext);
  const [lottie, setLottie] = useState({});
  const [message, setMessage] = useState('');
  const [messageErro, setMessageErro] = useState('');
  const [isOpenError, setIsOpenError] = useState(false);
  const { idPedido } = lastOrder;

  if (Object.keys(lottie).length === 0 && isLink !== '') {
    axios
      .get(isLink)
      .then(({ data }) => {
        setLottie(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Object.keys(lottie).length > 0 ? lottie : LoadingBeer,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  async function CloseModal(confirm = false, cancelado = false, msg = '') {
    connection?.close();
    setMessageErro(msg);
    setIsOpenError(cancelado);
    if (confirm) history.push('/success');
  }

  function streamToImage(hexString: any) {
    const bytes = new Uint8Array(
      hexString.match(/[\da-f]{2}/gi).map((h: string) => parseInt(h, 16)),
    );
    const blob = new Blob([bytes], { type: 'image/png' });
    return URL.createObjectURL(blob);
  }

  useEffect(() => {
    let timeout: number | undefined;
    if (Object.keys(selectedCompany).length === 0) {
      connection?.close();
      history.push('/');
    }

    connection?.addEventListener('message', async (data) => {
      if (data) {
        data = JSON.parse(data.data);
        if (data.type === 'tefMessage') {
          const mensagemTef = data.data;
          if (mensagemTef.idPedido === idPedido) {
            setMessage(mensagemTef.mensagem);
            timeout = setTimeout(() => {
              connection.send(JSON.stringify({ Received: true }));
              clearTimeout(timeout);
            }, 1000 * 1.5);
          }
        } else if (data.type === 'updatePaymentStatus') {
          const updated = data.data;
          if (updated.idPedido !== idPedido) return;
          CloseModal(
            updated.pagtoConcluido,
            updated.pagtoCancelado,
            updated.mensagem,
          );
        }
      }
    });
    return () => {
      connection?.removeEventListener('message', async (data) => {
        if (data) {
          data = JSON.parse(data.data);
          if (data.type === 'tefMessage') {
            const mensagemTef = data.data;
            if (mensagemTef.idPedido === idPedido) {
              setMessage(mensagemTef.mensagem);
              setInterval(() => {
                console.log('Enviando confirmação de recebimento');
                connection.send(JSON.stringify({ Received: true }));
              }, 1000 * 1.5);
            }
          } else if (data.type === 'updatePaymentStatus') {
            const updated = data.data;
            if (updated.idPedido !== idPedido) return;
            CloseModal(
              updated.pagtoConcluido,
              updated.pagtoCancelado,
              updated.mensagem,
            );
          }
        }
      });
    };
  }, []);

  return (
    <>
      {isOpenError && (
        <ModalPayment
          cancel={() => history.push('/')}
          retry={() => {
            setNewLastOrder({} as LastOrder);
            history.goBack();
          }}
          message={messageErro}
        />
      )}
      <Container>
        <section>
          <img src={selectedCompany.logotipo} alt="logoCompany" />
        </section>
        {message !== '' ? (
          message.includes('QRCODE') ? (
            <div id="PIX">
              <h1>PIX</h1>
              <img
                src={streamToImage(message.split(';')[1].replace('\\', ''))}
                alt="PIX"
              />
            </div>
          ) : (
            <div>
              <h1>{message}</h1>
            </div>
          )
        ) : (
          <>
            <Lottie
              options={defaultOptions}
              width="40rem"
              isClickToPauseDisabled
            />
            <div id="loading">
              <span>Carregando</span>
              <ThreeDotsContainer>
                <Dot />
                <Dot />
                <Dot />
              </ThreeDotsContainer>
              {/* <ThreeDots /> */}
            </div>
          </>
        )}
      </Container>
    </>
  );
}

/* <div>
          <button type="button" onClick={() => CloseModal()}>
            Cancelar
          </button>
        </div>  */
