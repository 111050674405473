import { darken, lighten } from 'polished';
import { FiArrowDown } from 'react-icons/fi';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

interface IContainerProps {
  visivelCart: boolean;
}

export const Container = styled.div<IContainerProps>`
  background: transparent;
  position: absolute;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem 1.5rem 0px 0px;
  //min-height: 27.813rem;
  height: ${({ visivelCart }) => (visivelCart ? '42rem' : '8rem')};
  bottom: 0;
  width: 100%;
  transition: height 0.5s;

  &::-webkit-scrollbar {
    display: none;
  }

  header {
    height: 8rem;
    display: flex;
    background: ${({ theme }) => theme && theme.colors.DefaultColor};
    color: ${({ theme }) =>
      theme && tinycolor(theme.colors.DefaultColor).isLight()
        ? darken(0.9, '#ffffff')
        : lighten(0.9, '#ffffff')};
    width: 100%;
    padding: 0.25rem 1rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    border-radius: 1.5rem 1.5rem 0px 0px;

    .prices {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 8rem;
      width: auto;
      background: none;
      margin-right: 1.5rem;
    }

    .prices .oldPrices {
      font-size: 1.3rem;
      text-decoration: line-through;
    }

    span:first-child {
      font-size: 3rem;
      margin-left: 2.5rem;
      font-family: 'Poppins';
    }

    span:last-child {
      font-size: 3rem;
      font-family: 'Poppins';
    }
  }

  div {
    width: 100%;
    background: ${({ theme }) => theme && theme.colors.Background};
    height: ${({ visivelCart }) => (visivelCart ? '38rem' : 0)};
    transition: height 0.5s;
    position: relative;

    ul {
      max-height: 34rem;
      list-style: none;
      //align-items: flex-start;
      padding: 0.5rem 1rem;
      padding-bottom: 7.5rem;
      white-space: nowrap;
      overflow-y: scroll;
      background: ${({ theme }) => theme && theme.colors.Background};
      color: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.8, theme.colors.Background)
          : lighten(0.8, theme.colors.Background)};

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        background: transparent;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #333;
        padding-bottom: 1rem;
        justify-content: space-between;

        & > div {
          display: flex;
          min-height: 6rem;
          height: auto;
          max-height: 12rem;
          justify-content: center;
          align-items: center;
          span:first-child {
            font-size: 1.5rem;
            margin-right: 0.5rem;
            font-family: 'Poppins';
          }

          aside {
            height: 5rem;
            width: 6.5rem;
            //padding: 0.35rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 0.5rem;

            img {
              border-radius: 0.5rem;
              width: 100%;
              height: 100%;
            }
          }

          & > div {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-left: 1.5rem;

            span:first-child {
              font-family: 'Poppins';
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 0.75rem;
              line-height: 1rem;
              font-size: 1.25rem;
              margin-bottom: 0.75rem;
              color: ${({ theme }) =>
                theme && tinycolor(theme.colors.DefaultColor).isLight()
                  ? darken(0.2, theme.colors.DefaultColor)
                  : lighten(0.2, theme.colors.DefaultColor)};
            }

            & > div {
              flex-direction: row;
              align-items: center;

              small {
                font-family: 'Poppins';
                line-height: 0.75rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 1rem;
                color: ${({ theme }) =>
                  theme && tinycolor(theme.colors.Background).isLight()
                    ? darken(0.8, theme.colors.Background)
                    : lighten(0.8, theme.colors.Background)};
              }

              small:nth-child(2) {
                font-family: 'Poppins';
                margin-left: 0.5rem;
                font-size: 1rem;
                color: ${({ theme }) =>
                  theme && tinycolor(theme.colors.Background).isLight()
                    ? darken(0.8, theme.colors.Background)
                    : lighten(0.8, theme.colors.Background)};
              }

              span {
                font-family: 'Poppins';
                font-size: 1rem;
                margin-left: 0.5rem;
                color: ${({ theme }) =>
                  theme && tinycolor(theme.colors.Background).isLight()
                    ? darken(0.8, theme.colors.Background)
                    : lighten(0.8, theme.colors.Background)};
              }
            }

            p {
              font-family: 'Poppins';
              font-size: 1rem;
              color: ${({ theme }) =>
                theme && tinycolor(theme.colors.Background).isLight()
                  ? darken(0.8, theme.colors.Background)
                  : lighten(0.8, theme.colors.Background)};
            }
          }
        }
        & > span {
          font-family: 'Poppins';
          margin-left: 1.5rem;
          margin-right: 3rem;
          font-size: 2.5rem;
          font-weight: bold;
        }
      }
    }

    .subItemArea {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .subItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        color: ${({ theme }) => theme && theme.colors.SecondaryColor};
        gap: 1rem;

        span {
          font-weight: 500;
          padding-top: 0.3rem;
        }
      }
      small {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .mainArea {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 1.5rem;
        color: ${({ theme }) => theme && theme.colors.DefaultColor};
      }
      small {
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.2, theme.colors.SecondaryColor)
            : theme.colors.SecondaryColor};
      }
    }

    .editPriceSide {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: auto;
      height: 100%;
      justify-content: flex-start;

      .finalValue {
        font-size: 2rem;
        font-weight: 700;
      }
    }

    .buttonEdit {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 4.5rem;
      gap: 1rem;
      padding: 0;
      margin: 0;
      button {
        background: ${({ theme }) =>
          tinycolor(theme.colors.Background).isLight()
            ? lighten(0.12, theme.colors.SecondaryColor)
            : theme.colors.SecondaryColor};
        color: ${({ theme }) => theme.colors.DefaultColor};
        height: 3.5rem;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        border: 0;
        font-size: 2rem;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      width: 3.5rem;
      height: 3.5rem;
      color: #ffffff;
      background: ${({ theme }) =>
        theme && tinycolor(theme.colors.Background).isLight()
          ? darken(0.2, theme.colors.SecondaryColor)
          : lighten(0.2, theme.colors.SecondaryColor)};
      border-radius: 50%;
      border: 0;
    }

    .divButton {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0.75rem;
      background: transparent;
      border: 0;
      position: absolute;
      bottom: 0;
      margin-bottom: 1.5rem;
      opacity: ${({ visivelCart }) => (visivelCart ? 1 : 0)};
      transition: opacity 1.3s;
      height: 6rem;

      button {
        font-family: Poppins;
        flex: 1;
        height: 5rem;
        border: 2;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.5rem;
        border-radius: 10px;
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.9, theme.colors.Background)
            : lighten(0.9, theme.colors.Background)};
        background: transparent;

        &:first-child {
          background: ${({ theme }) => theme && theme.colors.Background};
          margin-right: 1.25rem;
          color: ${({ theme }) => theme && theme.colors.DefaultColor};
          border: 2px solid ${({ theme }) => theme && theme.colors.DefaultColor};
        }

        &:last-child {
          margin-left: 1rem;
          background: ${({ theme }) => theme && theme.colors.DefaultColor};
          color: ${({ theme }) =>
            theme && tinycolor(theme.colors.DefaultColor).isLight()
              ? darken(0.9, '#ffffff')
              : lighten(0.9, '#ffffff')};
          border: 0;
        }

        &:last-child:disabled {
          filter: opacity(0.5);
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const FiArrowDownIcon = styled(FiArrowDown)`
  background: transparent;
  color: ${({ theme }) => theme.colors.DefaultColor};
  font-size: 2rem;
`;
