import { Footer } from './Footer';
import { Header } from './Header';
import { Main } from './Main';
import { Container } from './styles';

export function ModalReview(): JSX.Element {
  return (
    <Container>
      <div>
        <Header />
        <Main />
        <Footer />
      </div>
    </Container>
  );
}
