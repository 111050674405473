import { darken, lighten } from 'polished';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const Container = styled.div`
  overflow: scroll;
  min-height: 12rem;

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    header {
      font-family: 'Poppins';
      height: 5rem;
      display: flex;
      position: sticky;
      z-index: 10;
      top: 0;
      flex-direction: column;
      justify-content: center;
      background-color: ${({ theme }) => theme && theme.colors.Background};

      span {
        font-family: 'Poppins';
        font-size: 2rem;
        font-weight: 700;
        margin-left: 0.95rem;
        color: ${({ theme }) =>
          theme && tinycolor(theme.colors.Background).isLight()
            ? darken(0.8, theme.colors.Background)
            : lighten(0.8, theme.colors.Background)};
      }

      & > div {
        display: flex;
        align-items: center;
        margin-top: 0.625rem;
        height: 2.5rem;
        justify-content: space-between;
        align-items: center;

        span {
          font-family: 'Poppins';
          font-size: 1.5rem;
          font-weight: 400;
          color: ${({ theme }) => theme && theme.colors.DefaultColor};
        }

        div {
          border-radius: 4px;
          font-size: 1.25rem;
          //background: #343434;
          color: #fff;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          margin-right: 1.25rem;
          margin-bottom: 1rem;
        }

        img {
          height: 3rem;
          margin-right: 1.25rem;
        }
      }
    }

    ul {
      padding: 0 0.625rem;
      list-style: none;
      textarea {
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
        resize: none;
        width: 100%;
        height: 25vw;
        border: none;
      }
    }
  }
`;
