import { FaExclamationCircle } from 'react-icons/fa';
import { Container } from './styles';

interface ModalPaymentProps {
  cancel: () => void;
  retry: () => void;
  message: string;
}

export function ModalPayment({
  cancel,
  retry,
  message,
}: ModalPaymentProps): JSX.Element {
  return (
    <Container>
      <div>
        <FaExclamationCircle />
        <h1 id="mensagem_error">{message}</h1>
        <h1>Deseja tentar novamente?</h1>
        <div>
          <button type="button" onClick={cancel}>
            Não
          </button>
          <button type="button" onClick={retry}>
            Sim
          </button>
        </div>
      </div>
    </Container>
  );
}
